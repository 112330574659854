<template>
    <div class="user_info_body user_card_list">
        <el-tabs style="margin-left:1%" v-model="activeName" type="card" @tab-click="handleClick">
            <div class="user_title">
                <div>
                    <span class="user_title_name">会员卡列表</span>
                    <el-button round plain class="user_title_one" @click="back_prev">返回上一页</el-button>
                </div>
                
            </div>
            <el-tab-pane label="会员卡" name="vip_card">
                <el-table
                    :data="vip_card_list"
                    style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="ID"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="会员卡名称"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="balance"
                        label="会员卡余额">
                    </el-table-column>
                    <el-table-column
                        prop="is_integral_name"
                        label="是否参与积分"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="vip_time"
                        label="有效期"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="status_name"
                        label="状态"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="250">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            @click="handleUpEdit(scope.$index, scope.row)">续充</el-button>
                            <el-button
                            size="mini"
                            @click="handleExitEdit(scope.$index, scope.row)">退卡</el-button>
                            <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="次卡" name="c_card">
                <el-table
                    :data="c_card_list"
                    style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="ID"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="次卡名称"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="service_name"
                        label="服务名称">
                    </el-table-column>
                    <el-table-column
                        prop="remain_num"
                        label="可消费次数"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="giving"
                        label="赠送次数"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        label="售卡金额"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="pay_price"
                        label="实付金额"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="vip_time"
                        label="有效期"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="status_name"
                        label="状态"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="操作"
                        width="180">
                        <template slot-scope="scope">
                            <!-- <el-button
                            size="mini"
                            @click="handleUpEdit(scope.$index, scope.row)">续充</el-button> -->
                            <el-button
                            size="mini"
                            @click="handleExitEdit(scope.$index, scope.row)">退卡</el-button>
                            <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-dialog
			title="会员卡"
			:visible.sync="user_card_status"
			>
			<user-card v-if="user_card_status"  @child-click="addUserCardClick"></user-card>
		</el-dialog>
        <!-- 会员卡退卡 -->
        <el-dialog
			title="会员卡退卡"
			:visible.sync="user_card_exit_status"
			>
			<UserCardExit v-if="user_card_exit_status" :card_id="card_id" :activetype="activeType"  @child-click="addUserCardExitClick"></UserCardExit>
		</el-dialog>
    </div>
</template>
<script>
import UserCard from '../UserCard.vue';
import UserCardExit from './UserCardExit.vue';
import { getUserCardList, delMemberCard} from '@/api/user.js'

export default {
    name: 'UserCardList',
    components: {
		UserCard,
        UserCardExit,
	},
    data() {
        return {
            activeName: 'vip_card', //base_info:基础信息 order_list:门店订单 goods_list:消费商品 consume:服务消费 foster_consume:寄养消费 living_consume:活体消费 next_log:次卡记录 warn_list:提醒
            activeType: 1, //1:会员卡 2:次卡
            user_id:0, //当前用户ID
            vip_card_list:[], //会员卡列表
            c_card_list:[], //次卡列表
            user_card_status:false, //会员卡弹窗
            user_card_exit_status:false, //会员卡退卡弹窗
            card_id:0, //会员卡ID
        };
    },
    methods: {
        // 返回上一页
        back_prev() {
            this.$router.go(-1);
        },
        // 删除会员卡
        handleDelete(index, row) {
            console.log(index, row);
            // 删除
            this.$confirm('此操作将永久删除该会员卡, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delMemberCard({
                    card_id:row.id
                }).then(res => {
                    if(res.status == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getVipCardList();
                    }
                })
                // 删除
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 会员卡退卡 回调
        addUserCardExitClick() {
            this.user_card_exit_status = false;
            this.getVipCardList();
        },
        // 会员卡退卡
        handleExitEdit(index, row) {
            this.card_id = row.id;
            this.user_card_exit_status = true;
        },
        // 会员卡续充
        handleUpEdit(index, row) {
            localStorage.setItem('card_id',row.id);
			localStorage.setItem('card_vip_id',row.vip_id);
            this.user_card_status = true;
            console.log(index, row);
        },
        // 会员卡 回调
        addUserCardClick() {
            this.user_card_status = false;
            this.getVipCardList();
        },
        // 获取会员卡列表
        getVipCardList() {
            let params = {
                user_id: this.user_id,
                type: this.activeType
            }
            getUserCardList(params).then(res => {
                console.log(res);
                if (res.status == 200) {
                    if (this.activeType == 1) {
                        this.vip_card_list = res.data;
                    } else if (this.activeType == 2){
                        this.c_card_list = res.data;                    
                    }
                    // this.vip_card_list = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            }).catch(err => {
                this.$message.error(err);
            })
        },
        handleClick(tab, event) {
            if (tab.name == 'c_card') {
                this.activeType = 2;
                this.activeName = 'c_card';
            } else {
                this.activeType = 1;
                this.activeName = 'vip_card';
            }
            this.getVipCardList();
            // console.log(tab, event);
        }
    },
    mounted() {

        let url = window.location.href;   
		let user_id = url.split('user_id/')[1];   
        this.user_id = user_id;
        this.getVipCardList();
        // // 设置默认值
        // this.activeName = 'base_info';
        // this.activeType = 1;
        // console.log(this.user_id);
    }
};
</script>
<style>
.user_card_list .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll{
    width: 40%;
}
.user_card_list .el-tabs--card>.el-tabs__header .el-tabs__item{
    width: 50%;
    /* text-align: center; */
}
/* .el-tabs--card>.el-tabs__header .el-tabs__nav {
    width: 30%;
} */
.el-tabs--card>.el-tabs__header{
    border-bottom: 0;
    margin-left: 1%;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav {
    /* border-bottom:1; */
    border: 1px solid #ccc;
}
.user_info_body {
    background-color: #fff;
    margin: 20px 20px 60px 20px;
    border-radius: 12px;
    overflow-y: hidden;
    padding-top: 30px;
}
.user_title_name{
    cursor: pointer;
    margin-right: 20px;
	font-size: 18px;
    color: #333336;
    font-weight: 700;
}
.user_title_name{
    font-size: 20px;
    font-weight: bold;
}
.user_title_one{
    border: 1px solid #d9d9d9;
    background-color: #fff!important;
    color: #595959!important;
}   
.user_title{
    display: flex;
    justify-content: space-between;
}
.user_body{
    margin: 0 2.5% 0 2.5%;
    background-color: #fff;
    border-radius: 12px;
    overflow-y: hidden;
}
html, body {
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0;
    padding: 0;
}
</style>