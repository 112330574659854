<template>
    <div id="app">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="会员名称" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
                <el-input v-model="ruleForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input  show-password v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="角色" prop="role_type">
                <el-radio-group v-model="ruleForm.role_type">
                <el-radio label="普通用户"></el-radio>
                <el-radio label="店长"></el-radio>
                <el-radio label="员工"></el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="会员生日">
                <el-col :span="11">
                <el-form-item prop="birthday">
                    <el-date-picker  placeholder="选择日期" format="yyyy-MM-dd HH:mm" 
                value-format="timestamp"
                type="datetime" v-model="ruleForm.birthday" style="width: 100%;"></el-date-picker>
                </el-form-item>
                </el-col>
            </el-form-item> -->
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="ruleForm.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">{{ button_name }}</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { addUser, getUserInfo} from '@/api/home.js'
    export default {
        name: 'AddUser',
        props: {
            msg: String,
            user_id: String
        },
        data() {
            return {
                button_name:'立即创建',
                radio_status: "男",
                role_type_status: "普通用户",
                s_user_id : 0,
                ruleForm: {
                    name: '',
                    mobile: '',
                    password: '',
                    sex: '男',
                    role_type: '普通用户',
                    birthday: '',
                    remark: '',
                },
                rules: {
                    name: [
                        { required: true, message: '请输入用户名称', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    mobile: [
                        { required: true, message: '请选填写手机号', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            // 获取用户信息
            async getUserInfo(){
                let res = await getUserInfo({user_id: this.user_id});
                this.ruleForm.name = res.data.nickname;
                this.ruleForm.mobile = res.data.mobile;
                if (res.data.sex == 2) {
                    this.radio_status = "女";
                    this.ruleForm.sex =  "女";
                } else {
                    this.radio_status = "男";
                    this.ruleForm.sex =  "男";
                }

                if (res.data.role_type == 1) {
                    this.role_type_status = "普通用户";
                    this.ruleForm.role_type =  "普通用户";
                } else if (res.data.role_type == 2) {
                    this.role_type_status = "店长";
                    this.ruleForm.role_type =  "店长";
                } else if (res.data.role_type == 3) {
                    this.role_type_status = "员工";
                    this.ruleForm.role_type =  "员工";
                } else {
                    this.role_type_status = "普通用户";
                    this.ruleForm.role_type =  "普通用户";
                }
                // this.ruleForm.sex = this.radio_status;
                this.ruleForm.birthday = new Date(res.data.birthday);
                this.ruleForm.remark = res.data.remark;
                console.log(res);
            },

            async submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // console.log(formName);
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
                //创建一个对象 将表单数据添加到数组中
                let data_obj = {};
                data_obj.user_name = this.ruleForm.name;
                data_obj.mobile = this.ruleForm.mobile;
                data_obj.sex = this.ruleForm.sex;
                data_obj.role_type = this.ruleForm.role_type;
                // data_obj.birthday =  new Date(this.ruleForm.birthday).getTime();
                data_obj.remark = this.ruleForm.remark;
                data_obj.password = this.ruleForm.password;
                data_obj.user_id = this.s_user_id;
                data_obj = JSON.stringify(data_obj);
                
                
                //将表单数据添加到数组中
                // data_uesr.push(this.ruleForm);
                await addUser({data_obj}).then(res => {
                    if (res.status == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        //延时执行
                        setTimeout(() => {
                            this.$emit('child-click', data_obj);
                        }, 1000);
                        
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        mounted() {
            if (this.user_id == undefined || this.user_id == null || this.user_id == '' || this.user_id == 0) {
                console.log('没有用户id');
                // this.user_id = '0';
            } else {
                this.getUserInfo();
                this.s_user_id = this.user_id;
                this.button_name = '立即修改';
            }
            
        }
    }
</script>