<template>
    <div class="user_body">
        <div v-if="isShowArchivesInfo == 0">
            <div class="user_title">
                <div>
                    <span class="user_title_name">用户信息</span>
                    <el-button round plain class="user_title_one" @click="back_url">返回上一页</el-button>
                </div>
                <div style="margin-right: 2.5%;">
                    <el-button round class="user_title_one" @click="save_user=true">编辑用户</el-button>
                </div>
            </div>
            <!-- 用户信息 -->
            <div class="body_user_info">
                <div class="body_user_info_1">
                    <el-avatar :src="avatar" style="border-radius: 50%; width: 70px; height: 70px; display: block;"></el-avatar>
                    <div class="body_user_info_1_name">{{ nickname }}</div>     
                    <div class="body_user_info_1_mobile">
                        {{ mobile }}
                    </div>         
                </div>
                <div class="body_user_info_2">
                    <div class="member-info-row-item-list">
                        <!-- <img src="https://static.chonglaoban.cn/upload/icon/xcx/icon_member_number.png" alt="" class="member-info-row-icon"> -->
                        <div class="member-info-row-item-list-text">
                            <span>会员编号：</span>
                            <span>{{ id }}</span>
                        </div>
                    </div>
                    <div class="member-info-row-item-list">
                        <!-- <img src="https://static.chonglaoban.cn/upload/icon/xcx/icon_member_number.png" alt="" class="member-info-row-icon"> -->
                        <div class="member-info-row-item-list-text">
                            <span>会员生日: </span>
                            <span>{{ birthday }}</span>
                        </div>
                    </div>
                    <div class="member-info-row-item-list">
                        <!-- <img src="https://static.chonglaoban.cn/upload/icon/xcx/icon_member_number.png" alt="" class="member-info-row-icon"> -->
                        <div class="member-info-row-item-list-text">
                            <span>性别：</span>
                            <span>{{ sex }}</span>
                        </div>
                    </div>
                </div>
                <div class="body_user_info_3">
                    <div class="member-info-row-item-list">
                        <!-- <img src="https://static.chonglaoban.cn/upload/icon/xcx/icon_member_number.png" alt="" class="member-info-row-icon"> -->
                        <div class="member-info-row-item-list-text">
                            <span>备注：</span>
                            <span>{{ remark }}</span>
                        </div>
                    </div>
                    <!-- <div class="member-info-row-item-list">
                        <img src="https://static.chonglaoban.cn/upload/icon/xcx/icon_member_number.png" alt="" class="member-info-row-icon">
                        <div class="member-info-row-item-list-text">
                            <span>会员编号：</span>
                            <span>UiCeLs5hnBSy</span>
                        </div>
                    </div>
                    <div class="member-info-row-item-list">
                        <img src="https://static.chonglaoban.cn/upload/icon/xcx/icon_member_number.png" alt="" class="member-info-row-icon">
                        <div class="member-info-row-item-list-text">
                            <span>会员编号：</span>
                            <span>UiCeLs5hnBSy</span>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- 用户消费、会员卡 -->
            <div class="body_user_card">
                <el-form ref="form" label-width="124px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="用户ID" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        {{ id }}
                                        <!-- <span class="member-cell-number">{{ id }}</span> -->
                                    </p>
                                    <!-- <p class="mar-right">
                                        <i class="el-icon-arrow-right"></i>
                                    </p> -->
                                </div>
                            </el-form-item>
                            <el-form-item label="会员卡" >
                                <div class="body_user_card_form_div">
                                    <p class="pre-line pre-line-tow" @click="user_card_click">
                                        <span class="member-cell-number">共 {{ card_vip_count }} 个;</span>
                                    </p>
                                    <p class="mar-right">
                                        <i class="el-icon-arrow-right"></i>
                                    </p>
                                </div>
                            </el-form-item>
                            <el-form-item label="累计消费金额" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        ¥ {{ total_order_amount }}
                                    </p>
                                </div>
                            </el-form-item>
                            <el-form-item label="最近下单时间" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        {{ last_order_time }}
                                    </p>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="余额" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        <span class="">{{ user_money }}</span>
                                    </p>
                                    <!-- <p class="mar-right">
                                        <i class="el-icon-arrow-right"></i>
                                    </p> -->
                                </div>
                            </el-form-item>
                            <el-form-item label="次卡" >
                                <div class="body_user_card_form_div">
                                    <p class="pre-line pre-line-tow">
                                        <span class="member-cell-number">共 {{ card_ci_count }} 个;</span>
                                    </p>
                                    <p class="mar-right">
                                        <i class="el-icon-arrow-right"></i>
                                    </p>
                                </div>
                            </el-form-item>
                            <el-form-item label="累计消费次数" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                    {{ order_count }}
                                    </p>
                                </div>
                            </el-form-item>
                            <el-form-item label="注册时间" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        {{ create_time }}
                                    </p>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="积分" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        <span class="">{{ user_integral }}</span>
                                    </p>
                                    <!-- <p class="mar-right">
                                        <i class="el-icon-arrow-right"></i>
                                    </p> -->
                                </div>
                            </el-form-item>
                            <!-- <el-form-item label="优惠券" >
                                <div class="body_user_card_form_div">
                                    <p class="pre-line pre-line-tow">
                                        <span class="member-cell-number">{{ coupon_count }}</span>
                                    </p>
                                    <p class="mar-right">
                                        <i class="el-icon-arrow-right"></i>
                                    </p>
                                </div>
                            </el-form-item> -->
                            <el-form-item label="客单价" >
                                <div class="body_user_card_form_div_text">
                                    <p class="pre-line pre-line-tow">
                                        {{ average_order_amount }}
                                    </p>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!-- 宠物 -->
            <div class="body_user_pet" style="padding: 20px 0;">
                <div class="user_pet_button">
                    <el-button round size="small" style="margin-left: 20px;  border: 1px solid rgb(69, 183, 135); border-radius: 20px; background-color: rgb(69, 183, 135); color: rgb(255, 255, 255);" @click="add_pet_user">添加宠物</el-button>
                </div>
                <el-table
                    :data="pet_list"
                    style="width: 100%">
                    <el-table-column
                        label="宠物头像"
                        width="180">
                        <template slot-scope="scope">
                            <el-avatar :src="scope.row.photo"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="pet_name"
                        label="宠物名称"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="sex"
                        width="180"
                        label="性别">
                    </el-table-column>
                    <el-table-column
                        prop="variety"
                        width="180"
                        label="品种">
                    </el-table-column>
                    <el-table-column
                        prop="birthday"
                        width="180"
                        label="出生日期">
                    </el-table-column>
                    <el-table-column
                        prop="is_sterilize"
                        width="180"
                        label="绝育情况">
                    </el-table-column>
                    <el-table-column
                        prop="weight"
                        width="180"
                        label="体重">
                    </el-table-column>
                    <el-table-column
                        prop="next_time"
                        width="180"
                        label="下次疫苗/驱虫时间">
                    </el-table-column>
                    <el-table-column
                        prop="note"
                        width="180"
                        label="备注">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="180">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            @click="getRecord(scope.$index, scope.row)">宠物档案</el-button>
                            <el-button
                            size="mini"
                            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 添加宠物 -->
		<el-dialog
			title="宠物"
			:visible.sync="editor_pet_status"
			>
			<SavePetInfo v-if="editor_pet_status" :pet_id="pet_id" :user_id="user_id" @child-click="editPetInfo"></SavePetInfo>
		</el-dialog>
         <!-- 编辑用户 -->
        <el-dialog
            title="会员"
            :show-close="false"
            :visible.sync="save_user"
            width="80%"
            top="15vh"
            >
            <add-user v-if="save_user" :user_id="user_id"  @child-click="saveUserClick"></add-user>
        </el-dialog>
        <!-- 档案 -->
        <ArchivesInfo v-if="isShowArchivesInfo == 1" :next_pet_id="next_pet_id" @child-click="getArchivesInfo"></ArchivesInfo>
        
    </div>
</template>
<script>
import { getUserAllInfo} from '@/api/user.js'
import {  delPet} from '@/api/home.js'
import ArchivesInfo from '../ArchivesInfo.vue';
import SavePetInfo from '../pet_info/SavePetInfo.vue';
import AddUser from '../AddUser.vue';
export default {
    name: "BaseUserInfo",
    props: {
        msg: String,
        user_id: String,
    },
    components: {
		ArchivesInfo,
        SavePetInfo,
        AddUser,
	},
    data(){
        return {
            isShowUserCardList: 0,
            save_user:false,//新增用户弹窗
            editor_pet_status: false,//编辑宠物弹窗
            pet_id:'0',// 传参的宠物ID
            // 传参的宠物ID
            next_pet_id:0,
            // 是否展示档案页面
            isShowArchivesInfo: 0,
            // 用户信息
            nickname:'',//昵称
            mobile:'',//手机号
            card_ci_count:0,//次卡数量
            card_vip_count:0,//会员卡数量
            create_time:'',//注册时间
            coupon_count:0,//优惠券数量
            id:0,//用户ID
            last_order_time:'',//最近下单时间
            order_count:0,//订单数量
            remark:'',//备注
            total_order_amount:0,//累计消费金额
            sex:'',//性别
            user_integral:0,//用户积分
            user_money:0,//用户余额
            avatar:'',//头像
            birthday:'',//生日
            average_order_amount:0,//客单价



            pet_list:[{ //宠物列表
                id:1,//宠物ID
                pet_name:'小白',//宠物名称
                photo:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',//宠物头像
                variety:'金毛',
                birthday:'2022-06-05',
                weight:'160',
                sex:'弟弟',
                is_sterilize:'未绝育',
                note:'',
                last_time:'2023-08-06',
                next_time:'2023-08-06',
            }],
        }
    },
    methods:{
        // 返回上一页
        back_url(){
            this.$router.go(-1);
        },
        // 打开会员卡以页面
        user_card_click(){
            // 跳转页面
            this.$router.push({path:'/user_info/UserCardList/user_id/'+this.user_id});
            
            // this.$refs.user_card_list.show_user_card_list = true;
            // console.log('123123');
        },


        //编辑会员组件 供子组件调用
		saveUserClick(data) {
            this.save_user = false;
            this.getUserAllInfo();
		},

        // 获取用户信息
        async getUserAllInfo(){
            console.log(this.user_id);
            let res = await getUserAllInfo({user_id:this.user_id});
            this.nickname = res.data.nickname;
            this.mobile = res.data.mobile;
            this.card_ci_count = res.data.card_ci_count;
            this.card_vip_count = res.data.card_vip_count;
            this.create_time = res.data.create_time;
            this.coupon_count = res.data.coupon_count;
            this.id = res.data.id;
            this.last_order_time = res.data.last_order_time;
            this.order_count = res.data.order_count;
            this.remark = res.data.remark;
            this.total_order_amount = res.data.total_order_amount;
            this.user_integral = res.data.user_integral;
            this.user_money = res.data.user_money;
            this.avatar = res.data.avatar;
            this.birthday = res.data.birthday;
            this.average_order_amount = res.data.average_order_amount;
            if (res.data.sex == 2) {
                this.sex = '女';
            } else {
                this.sex = '男';
            }
            this.pet_list = res.data.pet_list;
            console.log(res);
        },
        
        // 新增宠物回调
        editPetInfo(e){
            this.editor_pet_status = false;
            this.getUserAllInfo();
        },
        // 跳转宠物档案 返回上一页回调
        getArchivesInfo(e){
            this.getUserAllInfo();
            this.next_pet_id = 0;
            this.isShowArchivesInfo = 0;
            console.log('---0001---')
            console.log(e);
        },
        // 跳转宠物档案
        getRecord(index, row){
            this.next_pet_id = row.id;
            this.isShowArchivesInfo = 1;
            // this.$router.push({
            //     path: '/user/petRecord',
            //     query: {
            //         pet_id: row.id,
            //         user_id: this.user_id,
            //     }
            // })
        },
        // 新增宠物
        add_pet_user(){
            this.pet_id = '0';
            this.editor_pet_status = true;
        },
        // 编辑宠物
        handleEdit(index, row) {
            console.log(index, row);
            this.pet_id = row.id;
            this.editor_pet_status = true;
        },
        // 删除宠物
        handleDelete(index, row){
            console.log(index);
            console.log(row);
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delPet({
                    id: row.id
                }).then(res => {
                    console.log(res);
                    if (res.status == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.pet_list.splice(index, 1);
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    },
    mounted(){
        console.log('---123-----');
        this.getUserAllInfo();
        // console.log(this.user_id);
    }
}

</script>
<style>
.user_pet_button button{
    margin-left: 5px;
    width: 85px;
    height: 30px;
    line-height: 10px;
}

.body_user_pet{
    width: 100%;
    padding: 0px;
}
.el-form-item__label{
    text-align: left;
}
.mar-right{
    margin: 0 22px 0 22px;
    height: 64px;
    line-height: 64px;
}
.body_user_card .member-cell-number{
    color: #0091ff;
}
.pre-line{
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    margin-right: 5px;
}
.pre-line-tow{
    -webkit-line-clamp: 2;
}
.body_user_card_form_div_text{
    display: flex;
    height: 64px;
    line-height: 22px;
    align-items: center;
    justify-content: space-between;
}
.body_user_card_form_div{
    cursor: pointer;
    display: flex;
    height: 64px;
    line-height: 22px;
    align-items: center;
    justify-content: space-between;
}
.body_user_card .el-form-item__content {
    height: 64px;
    line-height: 64px;
    padding-left: 20px;
    border-right: 1px solid #e6e6e6;
}
.body_user_card label{
    height: 64px;
    line-height: 64px;
    padding-left: 20px;
    background: #f9f9f9;
    font-weight: 400;
    color: #333336;
    border-right: 1px solid #e6e6e6;
}
.body_user_card .el-form-item {
    margin-bottom: 0;
    border-bottom: 1px solid #e6e6e6;
}   
.body_user_card{
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    overflow: hidden;
}
.member-info-row-item-list .member-info-row-icon
{
    width: 20px;
    height: 20px;
}
.member-info-row-item-list{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 33.3333333333%;
    width: 100%;
}
.body_user_info_1_mobile{
    font-size: 16px;
    font-family: PingFang;
    font-weight: 500;
    color: #333336;
    margin-left: 4px;
}
.body_user_info_1_name{
    width: 100%;
    font-size: 18px;
    font-family: PingFang;
    font-weight: 500;
    color: #333336;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}
.body_user_info_3{
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
    box-sizing: border-box;
}
.body_user_info_2{
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
    box-sizing: border-box;
}
.body_user_info_1{
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
.body_user_info{
    width: 99%;
    height: 202px;
    background: #f9f9f9;
    border: 1px solid #ecebed;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.user_title_name{
    cursor: pointer;
    margin-right: 20px;
	font-size: 18px;
    color: #333336;
    font-weight: 700;
}
.user_title_name{
    font-size: 20px;
    font-weight: bold;
}
.user_title_one{
    border: 1px solid #d9d9d9;
    background-color: #fff!important;
    color: #595959!important;
}   
.user_title{
    display: flex;
    justify-content: space-between;
}
.user_body{
    margin: 0 2.5% 0 2.5%;
    background-color: #fff;
    border-radius: 12px;
    overflow-y: hidden;
}
</style>