<template>
    <div class="user_body">
        <div class="user_title">
            <div>
                <span class="user_title_name">订单详情</span>
                <el-button round plain class="user_title_one" @click="back_url">返回上一页</el-button>
            </div>
            <div style="margin-right: 2.5%;">
                <el-button round class="user_title_one" @click="save_user=true">打印</el-button>
                <el-button round class="user_title_one" @click="save_user=true">撤单</el-button>
            </div>
        </div>
        <el-table
        :data="order_info"
        style="width: 100%">
            <el-table-column
                prop="order_sn"
                label="订单号"
                width="180">
            </el-table-column>
            <el-table-column
                prop="nickname"
                label="会员信息"
                width="180">
            </el-table-column>
            <el-table-column
                prop="mobile"
                label="手机号">
            </el-table-column>
            <el-table-column
                prop="order_amount"
                label="支付金额">
            </el-table-column>
            <el-table-column
                prop="pay_time"
                label="消费时间">
            </el-table-column>
            <el-table-column
                prop="create_time"
                label="创建订单时间">
            </el-table-column>
        </el-table>
        <h3>普通项目</h3>
        <el-table
        :data="order_goods_list"
        style="width: 100%">
            <el-table-column
                prop="goods_name"
                label="商品名称"
                >
            </el-table-column>
            <el-table-column
                prop="goods_price"
                label="商品价格"
                >
            </el-table-column>
            <el-table-column
                prop="goods_num"
                label="购买数量">
            </el-table-column>
            <el-table-column
                prop="total_pay_price"
                label="支付金额">
            </el-table-column>
        </el-table>

        <div class="pay-box">
            <p>
                <span>账单总价</span>
                <span>{{ base_order_info.goods_price }}</span>
            </p>
            <p>
                <span>优惠金额</span>
                <span>{{ base_order_info.discount_amount }}</span>
            </p>
            <p>
                <span>应付金额</span>
                <span>{{ base_order_info.order_amount }}</span>
            </p>
            <p>
                <span>实收金额</span>
                <span>{{ base_order_info.order_amount  }}</span>
            </p>
            <p>
                <span>支付</span>
                <span>{{ base_order_info.order_amount  }}</span>
            </p>
            <p>
                <span>退款</span>
                <span>{{ base_order_info.refund_amount }}</span>
            </p>
            <p>
                <span>备注</span>
                <span>{{ base_order_info.user_remark }}</span>
            </p>
        </div>
        <div style="margin-top: 20px; margin-bottom: 40px;">
            <el-button round class="user_title_one" @click="save_user=true">打印</el-button>
            <el-button round class="user_title_one" @click="save_user=true">撤单</el-button>
        </div>
    </div>
</template>
<script>
import { getOrderDetail} from '@/api/user.js'
export default {
    name: "BaseUserInfo",
    props: {
        msg: String,
        user_id: String,
    },
    
    data(){
        return {
            order_id: '',
            order_info:[],
            order_goods_list:[],
            base_order_info:{},
        }
    },
    methods:{
         // 返回上一页
         back_url(){
            this.$router.go(-1);
        },
        // 订单信息
        async getOrderDetail(){
            console.log(this.order_id);
            let res = await getOrderDetail({order_id:this.order_id});
            this.order_info = res.info.info;
            this.order_goods_list = res.info.goods_list;
            this.base_order_info = res.info.base_info;
            console.log(res);
        },
        
        
        
    },
    mounted(){
        this.order_id = this.$route.query.order_id;
        console.log('--778899--');
        console.log(this.order_id);
        this.getOrderDetail();
        // console.log(this.user_id);
    }
}

</script>
<style>
.pay-box p>span:first-child{
    width: 100px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;
    font-weight: 700;
}
.pay-box p{
    line-height: 40px;
}
.pay-box{
    border: 1px solid #ebeef5;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-top: 10px;
    padding: 10px 0;
}


.user_title_name{
    cursor: pointer;
    margin-right: 20px;
	font-size: 18px;
    color: #333336;
    font-weight: 700;
}
.user_title_name{
    font-size: 20px;
    font-weight: bold;
}
.user_title_one{
    border: 1px solid #d9d9d9;
    background-color: #fff!important;
    color: #595959!important;
}   
.user_title{
    display: flex;
    justify-content: space-between;
}
.user_body{
    margin: 0 2.5% 0 2.5%;
    background-color: #fff;
    border-radius: 12px;
    overflow-y: hidden;
    padding-top: 2%;
}
</style>