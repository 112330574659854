<template>
    <div id="app">
		<div class="pet_one">
            <el-input
                class="pet_input"
                placeholder="请输入内容"
                prefix-icon="el-icon-search"
                v-model="search_value">
            </el-input>
            <el-button round class="pet_bnt" v-on:click="getPendingOrderList()">搜索</el-button>
            <div>
                <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <el-table
                            :data="props.row.loc_table_data"
                            style="width: 100%">
                                <el-table-column
                                    align="center"
                                    prop="name"
                                    label="商品"
                                    >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="price"
                                    label="零售价"
                                    >
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="goods_num"
                                    label="数量">
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="min_count"
                                    label="小计">
                                </el-table-column>
                            </el-table>
                        </el-form>
                    </template>
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="商品订单号"
                    prop="order_sn"
                    width="180"
                    >
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="会员姓名"
                    prop="nickname">
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="手机号"
                    prop="phone">
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="总计"
                    prop="total">
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="挂单时间"
                    prop="create_time">
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="备注"
                    prop="remark">
                    </el-table-column>
                    <el-table-column  label="操作" align="center">
                        <template slot-scope="scope">
                        <el-button
                        class="pet_scope_bnt"
                        size="mini"
                        @click="handleEdit(scope.$index, scope.row)">解挂</el-button>
                        <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="ch-page">
                <el-pagination
                background
                class="ch-page-list"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="10"
                :pager-count="7"
                layout="total, prev, pager, next, jumper"
                :total="goods_total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getPendingOrderList, removePendingOrder, delPendingOrder} from '@/api/home.js'
export default {
	name: 'RemoveOrder',
	props: {
		msg: String
	},
	data() {
		return {
			search_value:'',
            goods_total: 0,//商品总数
			currentPage: 1, // 当前页
			page_num:1,//页码
            tableData: [{
                id: '12987122',
                name: '好滋好味鸡蛋仔',
                category: '江浙小吃、小吃零食',
                desc: '荷兰优质淡奶，奶香浓而不腻',
                address: '上海市普陀区真北路',
                shop: '王小虎夫妻店',
                shopId: '10333',
                loc_table_data: [
                    {
                        "id": "1",
                        "title": "yyyy",
                        "author": "admin",
                    },
                ],
            }]
		};
    },
    methods: {
        //解挂
        async handleEdit(index, row) {
            //删除 click_goods_list 缓存数据
            localStorage.removeItem('click_goods_list');
            //重新存入 click_goods_list 缓存数据
            localStorage.setItem('click_goods_list', JSON.stringify(row.loc_table_data));
            let del_res = await removePendingOrder({id:row.id})
            console.log(del_res);
            if(del_res.code == 1){
                // 将 user_info 写入缓存 覆盖 缓存里的 user_info
                localStorage.setItem('user_info', JSON.stringify(del_res.data));
                localStorage.setItem('user_id', JSON.stringify(del_res.data.id));
                this.$message({
                    message: '解挂成功',
                    type: 'success'
                });
                this.$emit('child-click', 'hello');
            }


            console.log(index, row);
        },
        //删除
        async handleDelete(index, row) {
            let del_res = await delPendingOrder({id:row.id})
            console.log(del_res);
            if(del_res.code == 1){
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.$emit('child-click', 'hello');
            }
            console.log(index, row);
        },
		//获取挂单列表 接口请求
        async getPendingOrderList(){
            //获取用户信息
            let user_info = localStorage.getItem('user_info');
            //判断数据是否为空
			if(user_info){
				//不为空 json化
				user_info = JSON.parse(user_info);
			} else {
				//为空  用户信息不存在 追加一个id 为0的用户信息
				user_info = {};
				user_info.id = 0;
			}
            let res = await getPendingOrderList({user_id:user_info.id,search:this.search_value, p:this.page_num})
            console.log(res);
            if(res.code == 1){
                this.tableData = res.data.list,
                this.goods_total = res.data.total
            }
        },
        handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			//分页
			this.page_num = val;
			this.getPendingOrderList();
		},
        
		
    },
	//初始化加载 页面第一次加载时调用
	mounted() {
		this.getPendingOrderList();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.pet_scope_bnt{
    background-color: #45b787;
    border: 1px solid #45b787;
    color: #ffffff;
}
.pet_scope_bnt:hover{
    background-color: #ffffff;
    color: #45b787;
}
.pet_bnt{
    margin-right: 10px;
    border-radius: 20px;
    background-color: #45b787;
    border: 1px solid #45b787;
    color: #ffffff;
}
.pet_bnt:hover{
    background-color: #ffffff;
    color: #45b787;
}
.pet_one button{
    margin-bottom: 20px;
}
.pet_one div{
    margin-bottom: 20px;
}
.pet_one .pet_input{
    width: 420px;
    margin-right: 10px;
}
.el-dialog__header{
    padding: 20px 20px 10px;
}
.el-dialog__body{
    padding: 14px 20px 50px;
}
</style>
