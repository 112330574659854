<template>
    <div id="app">
        <el-form :model="card_form" :rules="rules" ref="card_form" label-width="100px" class="demo-card_form">
            <div v-if="activetype == 2">
                <el-form-item label="售卡价格">
                    {{ price }}
                </el-form-item>
                <el-form-item label="实付金额">
                    {{ pay_price }}
                </el-form-item>
                <el-form-item label="服务">
                    {{ service }}
                </el-form-item>
                <el-form-item label="购买次数">
                    {{ buy_num }}
                </el-form-item>
                <el-form-item label="赠送次数">
                    {{ give_num }}
                </el-form-item>
                <el-form-item label="剩余次数">
                    {{ remain_num }}
                </el-form-item>
            </div>

            <div v-if="activetype == 1">
                <el-form-item label="有效期">
                    {{ valid_time }}
                </el-form-item>
                <el-form-item label="余额">
                    {{ balance }} 元
                    <!-- <span style="color: red;">累计充值金额：{{ total_balance }} 元</span> &nbsp;&nbsp;&nbsp;&nbsp; -->
                    <span style="color: red;">累计赠送： {{ gift_price }} 元</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style="color: red;">累计支付金额： {{ total_pay_price }} 元</span>
                    
                </el-form-item>
            </div>
            <el-form-item label="退款金额">
                <el-input type="number" placeholder="请输入退款金额" v-model="card_form.refund_price" style="width: 46%;">
                    <template  slot="append">&nbsp;&nbsp;&nbsp;元&nbsp;&nbsp;&nbsp;</template>
                </el-input>
            </el-form-item>
            <el-form-item label="退款方式">
                <el-select  v-model="card_form.pay_type" placeholder="请选择退款方式">
                    <el-option label="微信支付" value="1"></el-option>
                    <el-option label="支付宝支付" value="2"></el-option>
                    <el-option label="现金支付" value="3"></el-option>
                    <el-option label="POS机" value="4"></el-option>
                    <el-option label="其它" value="5"></el-option>
                </el-select>
            </el-form-item>
            
            
            <el-form-item label="收银员">
                <el-select  v-model="card_form.admin_user_id" placeholder="请选择收银员">
                    <el-option  v-for="item in admin_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="card_form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('card_form')">保存</el-button>
                <el-button @click="resetForm('card_form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { getMemberCardBaseInfo , saveMemberCard} from '@/api/home.js'
import { getMemberCardInfo, returnCard} from '@/api/user.js'
    export default {
        name: 'UserCard',
        props: {
            msg: String,
            card_id:Number,
            activetype:Number,
        },
        data() {
            return {
                // 有效期
                valid_time: '',
                // 余额
                balance: '',
                total_balance: '',//累计充值金额
                total_pay_price: '',//累计支付金额
                refund_price: '',//退款金额
                gift_price: '',//累计赠送金额

                // 售卡价格
                price: '',
                // 实际支付金额
                pay_price: '',
                // 服务
                service: '',
                // 购买次数
                buy_num: '',
                // 赠送次数
                give_num: '',
                // 剩余次数
                remain_num: '',


                // 退款方式
                pay_type: '',
                // 收银员
                admin_user_id: '',
                // 备注
                remark: '',
                // 会员卡列表 增加默认值
                card_list: [],
                select_disabled_one: false,
                //收银员列表
                admin_list: [],
                card_form: {
                    card_id: 0,
                    user_vip_id: '',
                    pay_type: '1',
                    refund_price: '',
                    price: '',
                    admin_user_id: '',
                    remark: '',
                },
                rules: {
                    user_vip_id: [
                        { user_vip_id: true, message: '请输入开通项目', trigger: 'blur' },
                    ]
                }
            }
        },
        methods: {
            // 获取会员卡信息
            async getMemberCardInfo() {
                await getMemberCardInfo({ card_id: this.card_id }).then(res => {
                    console.log(res);
                    if (res.status == 200) {
                        if(this.activetype == 1) {
                            // 会员卡到期时间
                            this.valid_time = res.data.vip_end_time;
                            // 余额
                            this.balance = res.data.balance;
                            // 累计充值金额
                            this.total_balance = res.data.total_balance;
                            // 累计支付金额
                            this.total_pay_price = res.data.total_pay_price;
                            // 累计赠送金额
                            this.gift_price = res.data.gift_price;
                            // 
                            // 退款金额
                            this.refund_price = res.data.refund_price;
                            this.card_form.refund_price = res.data.refund_price;
                        } else {
                            // 售卡价格
                            this.price = res.data.price;
                            // 实际支付金额
                            this.pay_price = res.data.pay_price;
                            // 服务
                            this.service = res.data.service_name;
                            // 购买次数
                            this.buy_num = res.data.buy_num;
                            // 赠送次数
                            this.give_num = res.data.giving;
                            // 剩余次数
                            this.remain_num = res.data.remain_num;
                            // 退款金额
                            this.refund_price = 0;
                            this.card_form.refund_price = 0;
                        }
                        
                        
                        this.card_form.card_id = this.card_id;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })
            },

            //获取办理会员卡基础信息
            async getMemberCardBaseInfo() {
                await getMemberCardBaseInfo().then(res => {
                    console.log(res);
                    if (res.status == 200) {
                        this.card_list = res.data.user_level_list;
                        this.admin_list = res.data.admin_list;
                        //默认会员卡ID
                        this.card_form.user_vip_id = res.data.user_level_list[0].id;
                        console.log(this.card_form.user_vip_id);
                        console.log('---card11111-----')
                        //默认收银员ID
                        this.card_form.admin_user_id = res.data.admin_list[0].id;
                        // 获取是否存在会员卡缓存ID
                        let card_id = localStorage.getItem('card_id');
                        let card_vip_id = localStorage.getItem('card_vip_id');
                        if(card_id && card_vip_id){
                            this.card_form.card_id = card_id;
                            this.card_form.user_vip_id = parseInt(card_vip_id);
                            this.select_disabled_one = true;
                            //清除缓存
                            localStorage.removeItem('card_id');
                            localStorage.removeItem('card_vip_id');
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },


            async submitForm(formName) {                
                //创建一个对象 将表单数据添加到数组中
                let data_obj = {};
                //当前用户ID
                data_obj.user_id = localStorage.getItem('user_id');
                data_obj.refund_price = this.card_form.refund_price;
                data_obj.pay_type = this.card_form.pay_type;
                data_obj.remark = this.card_form.remark;
                data_obj.card_id = this.card_form.card_id;
                data_obj = JSON.stringify(data_obj);
                
                //将表单数据添加到数组中
                // data_uesr.push(this.ruleForm);
                await returnCard({data_obj}).then(res => {
                    if (res.status == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        //延时执行
                        setTimeout(() => {
                            this.$emit('child-click', data_obj);
                        }, 1000);
                        
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        //初始化加载 页面第一次加载时调用
	mounted() {
        console.log('---card-----');
        console.log(this.card_id);
        this.getMemberCardInfo();
		this.getMemberCardBaseInfo();
		// this.select_disabled_one = false;
    }
}
</script>