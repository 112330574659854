<template>
    <div id="app">
        <el-form :model="pet_form" style="width: 90%;">
            <el-form-item v-if="active_type == 1" label="服务分类" label-width="110px">
                <el-select @change="service_change" v-model="pet_form.category_name" placeholder="请选择服务分类">
                    <el-option
                    v-for="item in service_list"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="active_type == 1" label="服务项目" label-width="110px">
                <el-select @change="goods_change" v-model="pet_form.goods_name" placeholder="请选择服务项目">
                    <el-option
                    v-for="item in goods_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="active_type == 2 || active_type == 3" label="使用药品" label-width="110px">
                <el-autocomplete
                    style="width: 50%;"
                    v-model="pet_form.service_name"
                    :fetch-suggestions="querySearchGoods"
                    placeholder="请输入内容"
                    @select="handleSelect"
                >
                    <template slot-scope="{ item }">
                        <span>{{ item.name }}</span>
                    </template>
                </el-autocomplete>
            </el-form-item>
            <el-form-item v-if="active_type == 2 || active_type == 3" label="类型" label-width="110px">
                <el-select @change="type_change" v-model="pet_form.type_name" placeholder="请选择类型">
                    <el-option
                    v-for="item in type_name_list"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item label="消费时间" label-width="110px">
                <!-- <el-input style="border-radius: 10px;" v-model="pet_form.birthday" autocomplete="off"></el-input> -->
                <el-date-picker
                v-model="pet_form.service_time"
                value-format="timestamp"
                format="yyyy-MM-dd HH:mm" 
                type="datetime"
                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="下次消费时间" label-width="110px">
                <el-date-picker
                v-model="pet_form.next_time"
                value-format="timestamp"
                format="yyyy-MM-dd HH:mm" 
                type="datetime"
                placeholder="选择日期">
                </el-date-picker>
                <el-checkbox style="margin-left:20px"   @change="change_warn" v-model="is_warn">是否提醒</el-checkbox>
            </el-form-item>
            <el-form-item label="提醒时间" label-width="110px" v-if="is_warn == true">
                <el-date-picker
                v-model="pet_form.warn_time"
                value-format="timestamp"
                format="yyyy-MM-dd HH:mm" 
                default-time="09:00:00"
                type="datetime"
                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.note" type="textarea" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addpet = false">取 消</el-button>
            <el-button type="primary" @click="addPetServiceRecord">确 定</el-button>
        </div>
    </div>
</template>

<script>
import {getGoodsServiceCategoryList, getGoodsServiceList,addPetServiceRecord, getPetServiceRecord} from '@/api/home.js'
export default {
    name: "SavePetRecord",
    props: {
		msg: String,
        pet_id: String,
        active_type: Number, //1:服务记录 2驱虫记录 3:疫苗记录 
        recird_id: Number,
	},
    data() {
        return {
            is_warn:true,
            is_submit:false,
            // 服务项目
            goods_list:[
                {id:1,name:'洗澡'},
            ],
            // 服务分类
            service_list:[
                {id:5,name:'其他'},
            ],
            // 类型
            type_name_list:[
                {name:'内驱'},
                {name:'外驱'},
                {name:'内外同驱'},
            ],
            imageUrl: '',
            // 宠物信息
			pet_form:{
                is_warn:'1', //是否提醒
                warn_time:'', //提醒时间
                goods_id:'', //服务项目id
                category_name:'服务分类', //服务分类
                goods_name:'服务项目', //服务项目
                service_time:'', //消费时间
                next_time:'', //下次消费时间	
                id:'', //宠物id
                pet_id:'', //宠物id
                note:'', //备注
                type:'1', 
                service_name:'', //使用药品
                type_name:'内驱', //类型
                recird_id:0, //记录id
			},
        };
    },
    methods:{
        // 获取服务记录详情
        async getPetServiceRecord(){
            let res = await getPetServiceRecord({id:this.recird_id});
            console.log('获取服务记录详情');
            console.log(res);
            // this.pet_form = res.data;
            this.pet_form.service_time = new Date(res.data.service_time);
            this.pet_form.next_time =  new Date(res.data.next_time);
            this.pet_form.warn_time =  new Date(res.data.warn_time);
            this.pet_form.goods_name = res.data.goods_name;
            this.pet_form.category_name = res.data.category_name;
            this.pet_form.service_name = res.data.service_name;
            this.pet_form.note = res.data.note;
            this.pet_form.goods_id = res.data.goods_id;
            this.pet_form.is_warn = res.data.is_warn;
            this.pet_form.type_name = res.data.type_name;
            if (res.data.is_warn == 1) {
                this.is_warn = true;
            } else {
                this.is_warn = false;
            }


            this.service_change(res.data.category_id);
            // this.pet_form.type_name = res.data.type_name;
            // if (res.data.is_warn == '1') {
            //     this.is_warn = true;
            // } else {
            //     this.is_warn = false;
            // }
        },
        change_warn(e)
        {
            if (e == false) {
                this.pet_form.is_warn = '0';
            } else {
                this.pet_form.is_warn = '1';
            }
        },
        handleSelect(item) {
            this.pet_form.service_name = item.name;
            console.log('---selects----');
            console.log(item);
            console.log('---selects----');
            // this.pet_form.goods_id = item.id;
        },
        // 搜索商品建议
        async querySearchGoods(walkSearch, cb){
            console.log(walkSearch);
            console.log('搜索商品建议');
            // walkSearch 不为空时，请求接口
            if (walkSearch != '') {
                let goods_list = await getGoodsServiceList({search_name:walkSearch});
                console.log(goods_list.data);
                cb(goods_list.data);
            }
            
        },
        // 获取服务分类
        async getGoodsServiceCategoryList(){
            let res = await getGoodsServiceCategoryList();
            console.log(res);
            if (res.status == 200) {
                this.service_list = res.data;
            }
        },
        // 获取服务商品列表
        async getGoodsServiceList(e){
            let res = await getGoodsServiceList({category_id:e});
            console.log(res);
            if (res.status == 200) {
                this.goods_list = res.data;
            }
        },
        type_change(e){
            // console.log('type_change');
            // console.log(e);
            this.pet_form.type_name = e;
        },
        service_change(e){
            console.log(e);
            this.getGoodsServiceList(e);
        },
        goods_change(e){
            console.log(e);
            console.log('---AAA----')
            this.pet_form.goods_id = e;
        },

        // 添加服务记录
        async addPetServiceRecord(){
            // 禁止重复提交
            if (this.is_submit) {
                return;
            }
            this.is_submit = true;
            // 药品名称不能为空
            if (this.pet_form.service_name == '') {
                this.$message({
                    message: '药品名称不能为空',
                    type: 'warning'
                });
                this.is_submit = false;
                return;
            }
            // 判断消费时间是否填写
            if (this.pet_form.service_time == '') {
                this.$message({
                    message: '消费时间不能为空',
                    type: 'warning'
                });
                this.is_submit = false;
                return;
            }
            this.pet_form.service_time = new Date(this.pet_form.service_time).getTime();
            // 判断下次消费时间是否填写 没有填写默认为空
            if (this.pet_form.next_time == '') {
                this.pet_form.next_time = '';
            } else {
                this.pet_form.next_time = new Date(this.pet_form.next_time).getTime();
            }
            // 判断是否提醒以勾选 勾选后提醒时间不能为空
            if (this.is_warn == true) {
                if (this.pet_form.warn_time == '') {
                    this.$message({
                        message: '提醒时间不能为空',
                        type: 'warning'
                    });
                    this.is_submit = false;
                    return;
                } else{
                    this.pet_form.warn_time = new Date(this.pet_form.warn_time).getTime();
                }
            }
            let res = await addPetServiceRecord(this.pet_form);
            console.log(res);
            if (res.status == 200) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                //延时执行
                setTimeout(() => {
                    this.$emit('child-click', '1');
                }, 1000);
            }
        },
    },
    mounted() {
        console.log('01231321313213213');
        console.log(this.recird_id);
        this.pet_form.type = this.active_type;
        this.pet_form.pet_id = this.pet_id;
        this.getGoodsServiceCategoryList();
        console.log(this.pet_form.pet_id);
        if (this.active_type == 2) {
            //驱虫
            this.type_name_list = [
                {name:'内驱'},
                {name:'外驱'},
                {name:'内外同驱'},
            ];
            this.pet_form.type_name = '内驱';
        } else if (this.active_type == 3) {
            //疫苗
            this.type_name_list = [
                {name:'普通疫苗'},
                {name:'狂犬疫苗'},
                {name:'进口疫苗'},
                {name:'国产疫苗'},
            ];
            this.pet_form.type_name = '普通疫苗';
        }
        // 判断是否是产看详情的数据
        if (this.recird_id > 0) {
            this.getPetServiceRecord();
            this.pet_form.recird_id = this.recird_id;
        }
    },
}
</script>