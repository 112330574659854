<template>
    <div id="app">
        <el-form :model="pet_form" style="width: 90%;">
            <el-form-item label="宠物名称" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.pet_name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="品种" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.variety" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别" label-width="110px">
                <el-radio-group v-model="pet_form.sex">
                    <el-radio  label="1">弟弟</el-radio>
                    <el-radio label="2">妹妹</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="宠物生日" label-width="110px">
                <!-- <el-input style="border-radius: 10px;" v-model="pet_form.birthday" autocomplete="off"></el-input> -->
                <el-date-picker
                v-model="pet_form.birthday"
                format="yyyy-MM-dd HH:mm" 
                value-format="timestamp"
                type="datetime"
                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="毛色" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.color" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="肩高/体长" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.long" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="体重" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.weight" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="绝育情况" label-width="110px">
                <el-radio-group v-model="pet_form.is_sterilize">
                    <el-radio  label="2">已绝育</el-radio>
                    <el-radio label="1">未绝育</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- http://www.petshopone.com -->
            <!-- http://petshop.webe.asia -->
            <el-form-item label="宠物头像" label-width="110px">
                <el-upload
                    class="avatar-uploader"
                    action="https://petshop.webe.asia/api/file/formimage"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="备注" label-width="110px">
                <el-input style="border-radius: 10px;" v-model="pet_form.note" type="textarea" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addpet = false">取 消</el-button>
            <el-button type="primary" @click="onsubmit">确 定</el-button>
        </div>
    </div>
</template>

<script>
import {addpet,getUserPetList,getPetInfo} from '@/api/home.js'
export default {
    name: "SavePetInfo",
    props: {
		msg: String,
        pet_id: String,
        user_id: String,
	},
    data() {
        return {
            imageUrl: '',
            // 宠物信息
			pet_form:{
				pet_name:'', //宠物名称
				variety:'金毛', //品种
				sex:'1', //性别
				birthday:new Date(), //宠物生日
				color:'', //毛色
				long:'', //肩高/体长
				weight:'', //体重
				is_sterilize:'1', //绝育情况
				pet_img:'', //宠物头像
				note:'', //备注			
				user_id:'', //用户id	
                id:'', //宠物id
			},
        };
    },
    methods:{
        // 获取当前宠物信息
        async getPetInfo(){
            let res = await getPetInfo({ pet_id: this.pet_id });
            console.log(res);
            if (res.status == 200) {
                
                this.pet_form.pet_name = res.data.pet_name;
                this.pet_form.variety = res.data.variety;
                this.pet_form.birthday = new Date(res.data.birthday);
                this.pet_form.color = res.data.color;
                this.pet_form.long = res.data.long;
                this.pet_form.weight = res.data.weight;
                this.pet_form.note = res.data.note;
                this.pet_form.id = res.data.id;
                this.pet_form.pet_img = res.data.photo;
                this.pet_form.sex = ''+res.data.sex+'';
                this.pet_form.is_sterilize = ''+res.data.is_sterilize+'';
                this.imageUrl = res.data.photo;
            }
        },


        //添加宠物
		async onsubmit(){
			this.pet_form.user_id = this.user_id;
			console.log(this.pet_form);
            this.pet_form.birthday = new Date(this.pet_form.birthday).getTime();
			let res = await addpet(this.pet_form);
            console.log('1231231');
            console.log(res);
			if (res.status == 200) {
				this.$message({
                    message: res.msg,
                    type: 'success'
                });
                //延时执行
                setTimeout(() => {
                    this.$emit('child-click', '1');
                }, 1000);
			} else {
				this.$message({
					message: res.msg,
					type: 'error'
				});
			}
			console.log(res);
			// this.addpet = false;
		},
        // 图片上传
		handleAvatarSuccess(res, file) {
			console.log(res);
			console.log('-----------');
			console.log(file);
			console.log('-----------');
			this.pet_form.pet_img = res.data.base_url;
			this.imageUrl = URL.createObjectURL(file.raw);
		},
    },
    mounted() {
        console.log('01231321313213213');
        console.log(this.user_id);
        if (this.pet_id == 0) {
            this.pet_form.user_id = this.user_id;
            this.pet_form.id = this.pet_id;
        } else {
            this.getPetInfo();
        }
        
        console.log(new Date());
    },
}
</script>