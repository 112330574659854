<template>
    <div id="app">
        <el-table
        :data="tableData"
        style="width: 100%">
            <el-table-column
            label="商品名称"
            prop="name">
            </el-table-column>
            <el-table-column
                label="提醒时间"
                prop="day_num">
                <template slot-scope="day_num">
                    <el-input size="small" v-model="day_num.row.day_num"  placeholder="多少天后提醒"><template slot="append">天后</template></el-input>
                </template>
            </el-table-column>
            
        </el-table>
        <el-button type="primary" @click="save_warn">保存</el-button>
        <el-button @click="resetForm('card_form')">重置</el-button>
    </div>
</template>
<script>
import { saveGoodsWarn , saveMemberCard} from '@/api/home.js'
    export default {
        name: 'GoodsWarn',
        props: {
            msg: String
        },
        data() {
            return {
                select_disabled_one: false,
                save_bnt:0,
                //收银员列表
                admin_list: [],
                card_form: {
                    warn_day: 1,
                    
                },
                tableData:[{
                    goods_name: '测试商品',
                    day_num: 1,
                }],
            }
        },
        methods: {
            async save_warn(){
                // 防止重复点击
                if (this.save_bnt == 1) {
                    return false;
                }
                this.save_bnt = 1;

                let data_obj = {};
                console.log(localStorage.user_id);
                data_obj = JSON.stringify(this.tableData);
                let user_id = localStorage.user_id;
                // data_obj.user_id = localStorage.user_id;
                console.log(data_obj);
                await saveGoodsWarn({data_obj,user_id}).then(res => {
                    if (res.status == 200) {
                        // 删除缓存
                        localStorage.removeItem('warn_goods_list');
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        //延时执行
                        setTimeout(() => {
                            this.$emit('child-click', data_obj);
                        }, 1000);
                        
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },
            async submitForm(formName) {                
                //创建一个对象 将表单数据添加到数组中
                let data_obj = {};
                //当前用户ID
                data_obj.user_id = localStorage.getItem('user_id');
                data_obj.type = 1; //1：会员卡 2:次卡
                data_obj.vip_id = this.card_form.user_vip_id;
                data_obj.pay_price = this.card_form.price;
                data_obj.pay_type = this.card_form.pay_type;
                data_obj.user_pay_price = this.card_form.user_pay_price;
                data_obj.remark = this.card_form.remark;
                data_obj.card_id = this.card_form.card_id;
                data_obj = JSON.stringify(data_obj);
                
                //将表单数据添加到数组中
                // data_uesr.push(this.ruleForm);
                await saveMemberCard({data_obj}).then(res => {
                    if (res.status == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        //延时执行
                        setTimeout(() => {
                            this.$emit('child-click', data_obj);
                        }, 1000);
                        
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 获取复购数据
            getGoodsList(){
                // let data_obj = {};
                // 获取缓存中的数据
                let warn_goods_list = localStorage.warn_goods_list;
                if (warn_goods_list) {
                    this.tableData = JSON.parse(warn_goods_list);
                }
                console.log('---复购---');
                console.log(this.tableData);
                console.log('---复购---');
            },
        },
        //初始化加载 页面第一次加载时调用
	mounted() {
        this.getGoodsList();
		// this.getMemberCardBaseInfo();
		this.select_disabled_one = false;
        // 删除缓存
        localStorage.removeItem('warn_goods_list');
    }
    }
</script>