<template>
    <div class="user_body">
        <el-table
        :data="tableData"
        style="width: 100%">
            <el-table-column
                prop="goods_name"
                label="商品名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="goods_num"
                label="数量"
                width="180">
            </el-table-column>
            <el-table-column
                prop="goods_price"
                label="零售价">
            </el-table-column>
            <el-table-column
                prop="total_price"
                label="商品总价">
            </el-table-column>
            <el-table-column
                prop="total_pay_price"
                label="实付金额">
            </el-table-column>
            <el-table-column
                prop="create_time"
                label="消费时间"
                width="180">
            </el-table-column>
        </el-table>
        <div class="pet_bottom">
            <div class="block">
                <!-- 分页 -->
                <el-pagination style="float: right;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10]"
                :page-size="page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { getOrderConsumptionList} from '@/api/user.js'
export default {
    name: "ConsumptionList",
    props: {
        msg: String,
    },
    
    data(){
        return {
            tableData:[],
            currentPage: 1, // 分页
            total: 30, // 总数
            page_size: 10, // 每页显示多少条
            user_id:0, //当前用户ID


        }
    },
    methods:{
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getOrderGoodsList();
        },
        // 查看详情
        handleEdit(index, row) {
            console.log(index, row);
            this.$router.push({
                path: '/user_info/OrderDetail',
                query: {
                    order_id: row.id,
                }
            })
        },
        // 获取该用户商品列表
        getOrderConsumptionList(){
            let params = {
                user_id: this.user_id,
                page: this.currentPage,
                page_size: this.page_size,
            }
            getOrderConsumptionList(params).then(res=>{
                console.log('--123---');
                // console.log(res.data);
                this.tableData = res.data.list;
                this.total = res.data.count;
            })
        },
        

    },
    mounted(){
        let url = window.location.href;   
		let user_id = url.split('user_id/')[1];   
        this.user_id = user_id;
        this.getOrderConsumptionList();
    }

  
}

</script>
<style>
.pet_bottom{
	margin-top: 20px;
}
</style>