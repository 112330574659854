<template>
    <div id="app" style="background-color: #f7f7f7;padding:20px 18px 14px 18px;overflow: hidden;height:calc(100% - 2px)">
		<el-row>
			<el-col :span="7">
				<div style="width: 100%;">
					<div v-if="is_user == 1" style="margin: 0 0%;height: 120px;background: #45b787; border-radius: 15px; ">
						<el-row>
							<el-col :span="5">
								<el-avatar :size="60" style="margin-top: 30px; margin-left: 20%;" :src="user_name_img"></el-avatar>
							</el-col>
							<el-col :span="10">
								<div style="margin-left: 10%; margin-top: 30px;color: #ffffff;">
									<div style="font-size: 15px;">
										<span style="cursor: pointer;" @click="open_user_info(user_id)">{{ user_name }}</span>	
									</div>
									<div style="font-size: 12px; margin-top: 10px; ">
										<div>{{ user_mobile }}</div>
										<div>会员编号: {{ user_id }}</div>
									</div>
								</div>
							</el-col>
							<el-col :span="9">
								<div style="width: 100%; height: 30px; margin-top: 30px;">
									<el-button round size="mini" style="width:100px ;color: #45b787!important;" v-on:click="user_switch()">切换</el-button>
									<!-- <el-button round size="mini" style="color: #606266!important">散客</el-button> -->
								</div>
							</el-col>
						</el-row>
					</div>
					<!-- 散客 -->
					<div class="left-top-two" v-if="is_user == 0">
						<div style="padding: 15px;">
							<el-autocomplete
								prefix-icon="el-icon-search"
								style="border-radius: 60px;width: 100%;"
								v-model="user_name_search"
								:fetch-suggestions="walkSearch"
								placeholder="请输入内容"
								:trigger-on-focus="false"
								@select="walkSelect"
								>
								<template slot-scope="{ item }">
									<span>{{ item.nickname }}</span>
									<span>-</span>
									<span>{{ item.mobile }}</span>
								</template>
							</el-autocomplete>
						</div>
						<div>
							<el-button round style="background: #45b787; color: #ffffff;margin: 0 20px 15px; width: calc(100% - 40px);" v-on:click="new_user()">新增会员</el-button>
						</div>
						
					</div>
				</div>
				<!-- <el-divider></el-divider> -->
				<!-- 信息 -->
				<div style="margin-top: 30px;background: #ffffff; border-radius: 12px; height: calc(101vh - 256px);padding-bottom:20px;">
					<el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true" style="padding: 10px; ">
						<el-tab-pane class="vip_card_list_class" label="会员卡" name="vip_card" style=" height: calc(92vh - 256px);overflow-y:scroll;overflow-x:hidden;">
							<div>
								<el-link style="float: left; color: #45b787;" @click="user_card_status=true">开通会员卡</el-link>
								<el-link style="float: right; color: #45b787;"  @click="user_count_card_status=true">开通次卡</el-link>
							</div>
							<div  v-for="(item, index) in vip_card_list" :key="item.id" v-on:click="vip_card_active(index,item)" class="div-card" :style="{backgroundSize: '100% 100%',backgroundImage: 'url(' + item.background_image + ')'}">
								<p class="div-card-p">{{ item.name }}</p>
								<div class="card-price" v-if="item.type == 1">
									<span>
										¥
										<span>{{ item. balance}}</span>
									</span>
									<el-button round size="mini" style="width:20%;margin-right: 5%; float: right;color: #45b787!important;" v-on:click="pay_card(item.id, item)">充值</el-button>
								</div>
								<div class="card-price" v-if="item.type == 2">
									<span>
										{{ item.service_name }} 剩余
										<span>{{ item. remain_num}}</span>
										次
									</span>
								</div>
								<div class="card-date">
									<div>{{ item.vip_time }}</div>
									<div class="clbtext-overflow">迪卡宠物</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane name="first">
							<div style=" padding: 0;" slot="label">会员信息</div>
							<!-- 卡片 数据卡展示6个 -->
							<el-row :gutter="12">
								<el-col style="margin-bottom: 10px;" :span="8">
									<el-card shadow="hover" style="height: 80px;">
										<div style="text-align: center; font-size:13px ">会员积分</div>
										<div style="text-align: center;">{{ user_integral }}</div>
									</el-card>
								</el-col>
								<el-col style="margin-bottom: 10px; " :span="8">
									<el-card shadow="hover" style="height: 80px;">
										<div style="text-align: center;font-size:13px">会员资产</div>
										<div style="text-align: center;">{{ user_money }}</div>
									</el-card>
								</el-col>
								<el-col style="margin-bottom: 10px; " :span="8">
									<el-card shadow="hover" style="height: 80px;">
										<div style="text-align: center;font-size:13px">上次消费时间</div>
										<div style="text-align: center;font-size:13px;">{{ last_order_time }}</div>
									</el-card>
								</el-col>
								<el-col style="margin-bottom: 10px; " :span="8">
									<el-card shadow="hover" style="height: 80px;">
									<div style="text-align: center;font-size:13px">总消费金额</div>
									<div style="text-align: center;">{{ total_order_amount }}</div>
									</el-card>
								</el-col>
								<el-col style="margin-bottom: 10px; " :span="8">
									<el-card shadow="hover" style="height: 80px;">
									<div style="text-align: center;font-size:13px">总消费次数</div>
									<div style="text-align: center;">{{ order_count }}</div>
									</el-card>
								</el-col>
								<el-col style="margin-bottom: 10px; " :span="8">
									<el-card shadow="hover" style="height: 80px;">
									<div style="text-align: center;font-size:13px">客单价</div>
									<div style="text-align: center;">{{ average_order_amount }}</div>
									</el-card>
								</el-col>
							</el-row>
						</el-tab-pane>
						<el-tab-pane name="second">
							<div style=" padding: 0;" slot="label">宠物信息</div>
							<div>
								<el-button  @click="addpet=true" round style="width: 100%; background: #45b787; color: #ffffff;">添加宠物</el-button>
								<div style="margin: 5% 0 0 2%; height: calc(92vh - 295px);overflow-y:scroll;overflow-x:hidden;">
									<div  v-for="item in user_pet_list" :key="item.id">
										<el-avatar style="height: 46px; width: 46px; line-height: 46px;"  :size="60" :src="item.photo"></el-avatar>
										<div class="pet-text">
											<p>
												{{ item.pet_name }}
												<span>{{ item.color }}</span>
												<span>{{ item.variety }}</span>
											</p>
											<!-- <p>生日：{{ item.birthday }}</p> -->
											<p>备注：{{ item.note }}</p>
										</div>
										<div>
											<el-button round size="mini" style="margin-bottom: 5px;" @click="archives_info(item.id)">档案</el-button>
										</div>
									</div>									
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-col>
			<!-- 功能 -->
			<!-- <el-col :span="3">
				<div style="margin-top: 40px;">
					<div class="function_one">
						商品
					</div>
					<div class="function_one">
						整单取消
					</div>
					<div class="function_one">
						挂单/取单
					</div>
					
				</div>
			</el-col> -->
			<el-col :span="17" style="">
				<div class="goods-left">
					<div class="goods-one">
						<div style="width: 95%; margin: 1% 3%;">
							<div class="goods-top">
								<!-- <div class="goods-top-bu" style="background-color: #45b787; color: #ffffff;">
									选购
								</div> -->
								<div >
									<el-button round style="background-color: #45b787; color: #ffffff;" v-on:click="choose_goods()">选购</el-button>
									<el-button style="margin-right: 12px;" round v-on:click="pending_order()">挂单</el-button>
									<!-- <el-button style="margin-right: 12px;" round v-on:click="user_info()">测试</el-button> -->
									<el-badge  :value="pending_order_num" class="item">
										<el-button round v-on:click="remove_order()">解挂</el-button>
									</el-badge>
									<div class="goods-top-search">
										<el-input v-model="input_search" placeholder="请输入商品/服务/条形码" suffix-icon="el-icon-search" @change="change_aa"></el-input>
									</div>
								</div>
								<!-- <div class="goods-top-bu">
									挂单/解挂 
								</div> -->
								<!-- <div class="goods-top-search">
									<el-button icon="el-icon-search" circle></el-button>
								</div> -->
								
							</div>
						</div>
						<el-divider></el-divider>
						<div style="height:calc(103vh - 313px);overflow-y:auto">
							<el-table
								:data="tableData"
								style="width: 100%">
								<!-- <el-table-column type="expand"> -->
								<!-- <el-table-column type="index">
									<template slot-scope="props">
										<el-form label-position="left" inline class="demo-table-expand">
										<el-form-item label="商品名称">
											<span>{{ props.row.name }}</span>
										</el-form-item>
										<el-form-item label="所属店铺">
											<span>{{ props.row.shop }}</span>
										</el-form-item>
										<el-form-item label="商品 ID">
											<span>{{ props.row.id }}</span>
										</el-form-item>
										<el-form-item label="店铺 ID">
											<span>{{ props.row.shopId }}</span>
										</el-form-item>
										<el-form-item label="商品分类">
											<span>{{ props.row.category }}</span>
										</el-form-item>
										<el-form-item label="店铺地址">
											<span>{{ props.row.address }}</span>
										</el-form-item>
										<el-form-item label="商品描述">
											<span>{{ props.row.desc }}</span>
										</el-form-item>
										</el-form>
									</template>
								</el-table-column> -->
								<el-table-column
								prop="thumbnail"
								label="商品图片">
								<template slot-scope="scope">
									<!-- <img :src="scope.row.thumbnail" min-width="40" height="40"/> -->
									<el-avatar :src="scope.row.thumbnail" style="height: 50px; width: 50px; margin: 15px 0 0 12px;"></el-avatar>
								</template>
								</el-table-column>
								<el-table-column
								label="商品名称"
								prop="name">
								</el-table-column>
								<el-table-column
									label="原价"
									prop="original_price">
									<template slot-scope="original_price">
										<el-input size="small" :disabled="true" v-model="original_price.row.price" placeholder="原价"></el-input>
									</template>
								</el-table-column>
								<el-table-column
									label="零售价"
									prop="price">
									<template slot-scope="price">
										<el-input size="small" v-model="price.row.discount_price" @blur="discount_price_eidt(price.row, price.$index)" placeholder="零售价"></el-input>
									</template>
								</el-table-column>
								<el-table-column
									label="折扣率"
									prop="discount">
									<template slot-scope="discount">
										<el-input size="small" v-model="discount.row.discount" @blur="discount_eidt(discount.row, discount.$index)" placeholder="折扣率"><template slot="append">%</template></el-input>
									</template>
								</el-table-column>
								<el-table-column
									label="数量"
									prop="goods_num">
									<template slot-scope="goods_num">
										<el-input-number size="mini" v-model="goods_num.row.goods_num" @change="handleChange(goods_num.row, goods_num.$index)" :min="1" :max="99" ></el-input-number>
									</template>
									
								</el-table-column>
								<el-table-column
									label="小计"
									prop="min_count">
									<template slot-scope="min_count">
										<el-input size="small" v-model="min_count.row.min_count" @blur="min_count_eidt(min_count.row, min_count.$index)" placeholder="小计"></el-input>
									</template>
								</el-table-column>
								<el-table-column
									fixed="right"
									label="操作"
									width="150">
									<template slot-scope="scope">
										<el-tooltip class="item" effect="dark" placement="top">
											<div slot="content" v-html="scope.row.all_pet_name"></div>
											<el-button  @click="select_pet_click(scope.row, scope.$index)"  size="mini"  type="warning" circle>{{ scope.row.pet_name }}</el-button>
										</el-tooltip>
										<el-button v-if="scope.row.is_give == 0" @click="giveHandleClick(scope.row, scope.$index)" circle  size="small">送</el-button>
										<el-button v-if="scope.row.is_give == 1" @click="cancel_give(scope.row, scope.$index)" type="warning" circle  size="small">送</el-button>
										<el-button @click="delHandleClick(scope.row, scope.$index)"  circle size="small">删</el-button>
										<!-- <el-button type="text" size="small">编辑</el-button> -->
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
				<!-- 底部 -->
				<div class="goods-bottom">
					<div class="goods-bottom-two">
						<div style="display: inline-block;">
							总价
							<span>¥ {{ total_price }}</span>
						</div>
						<div style="margin-left: 20px;display: inline-block;">
							优惠
							<span>¥ {{ total_discount_price }}</span>
						</div>
						<div style="margin-left: 20px;display: inline-block;">
							应付金额
							<span style="font-size:25px">¥ {{ pay_price }}</span>
						</div>
						<button type="button" class="el-button clb-btn el-button--default" @click="drawer = true"  style="margin-left:20px;width: 180px;border:1px solid #45b787;border-radius:20px;background-color: #45b787; color: #ffffff;"><span>去结账</span></button>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-dialog
		class="dialog_one"
		:show-close="false"
		:visible.sync="dialogVisible"
		width="80%"
		top="15vh"
		@close="dialog_close"
		>
		<choose-goods v-if="dialogVisible"  @child-click="handleChildClick"></choose-goods>
		</el-dialog>
		<!-- 抽屉 -->
		<el-drawer
		title="我是标题"
		:visible.sync="drawer"
		v-if="drawer"
		:with-header="false"
		>
		<drawer-pay></drawer-pay>
		</el-drawer>
		<!-- 解挂 列表 -->
		<el-dialog
		title="挂单列表"
		:show-close="false"
		:visible.sync="remove_order_status"
		width="80%"
		top="15vh"
		@close="remove_order_dialog_close"
		>
		<remove-order v-if="remove_order_status"  @child-click="removeOrderClick"></remove-order>
		</el-dialog>
		<!-- 新增会员 -->
		<el-dialog
		title="新增会员"
		:show-close="false"
		:visible.sync="add_user"
		width="80%"
		top="15vh"
		@close="add_user_dialog_close"
		>
		<add-user v-if="add_user"  @child-click="addUserClick"></add-user>
		</el-dialog>
		<!-- 新增会员后 展示用户信息 -->
		<el-dialog
		title="会员信息"
		:visible.sync="centerDialogVisible"
		width="30%"
		>
		<el-form  label-width="150px">
			<el-form-item  label="会员姓名：">
				{{ user_name }}
			</el-form-item>
			<el-form-item label="手机号：">
				{{ user_mobile }}
			</el-form-item>
			<el-form-item label="会员宠物：">
				暂无
			</el-form-item>
			<el-form-item label="会员卡：">
				暂无
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button round style="background: #45b787; color: #ffffff;float:left; width: calc(45%);" @click="addpet=true">添加宠物</el-button>
			<el-button round style="background: #45b787; color: #ffffff; width: calc(45%);" @click="user_card_status=true">办理会员卡</el-button>
		</div>
		<div slot="footer" class="dialog-footer" style="margin-top: 10px;">
			<el-button round style="background: #45b787; color: #ffffff;float:left; width: calc(45%);" @click="user_count_card_status=true">办理次卡</el-button>
			<el-button round style="background: #45b787; color: #ffffff; width: calc(45%);" @click="centerDialogVisible=false">立即消费</el-button>
		</div>
		</el-dialog>
		<!-- 添加宠物 -->
		<el-dialog title="添加宠物" :visible.sync="addpet">
			<el-form :model="pet_form" style="width: 90%;">
				<el-form-item label="宠物名称" label-width="110px">
					<el-input style="border-radius: 10px;" v-model="pet_form.pet_name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="品种" label-width="110px">
					<el-input style="border-radius: 10px;" v-model="pet_form.variety" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="性别" label-width="110px">
					<el-radio-group v-model="pet_form.sex">
						<el-radio  label="1">弟弟</el-radio>
						<el-radio label="2">妹妹</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="宠物生日" label-width="110px">
					<!-- <el-input style="border-radius: 10px;" v-model="pet_form.birthday" autocomplete="off"></el-input> -->
					<el-date-picker
					v-model="pet_form.birthday"
					type="date"
					placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="毛色" label-width="110px">
					<el-input style="border-radius: 10px;" v-model="pet_form.color" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="肩高/体长" label-width="110px">
					<el-input style="border-radius: 10px;" v-model="pet_form.long" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="体重" label-width="110px">
					<el-input style="border-radius: 10px;" v-model="pet_form.weight" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="绝育情况" label-width="110px">
					<el-radio-group v-model="pet_form.is_sterilize">
						<el-radio  label="2">已绝育</el-radio>
						<el-radio label="1">未绝育</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="宠物头像" label-width="110px">
					<el-upload
						class="avatar-uploader"
						action="http://www.petshopone.com/api/file/formimage"
						:show-file-list="false"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload">
						<img v-if="imageUrl" :src="imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="备注" label-width="110px">
					<el-input style="border-radius: 10px;" v-model="pet_form.note" type="textarea" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addpet = false">取 消</el-button>
				<el-button type="primary" @click="onsubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 办理会员卡 -->
		<el-dialog
			title="办理会员卡"
			:visible.sync="user_card_status"
			>
			<user-card v-if="user_card_status"  @child-click="addUserCardClick"></user-card>
		</el-dialog>
		<!-- 办理次卡 -->
		<el-dialog
			title="办理次卡"
			:visible.sync="user_count_card_status"
			>
			<user-count-card v-if="user_count_card_status"  @child-click="addUserCountCardClick"></user-count-card>
		</el-dialog>
		<!-- 提醒 -->
		<el-dialog
			title="新增提醒"
			:visible.sync="user_goods_warn_status"
			>
			<GoodsWarn v-if="user_goods_warn_status"  @child-click="userGoodsWarnClick"></GoodsWarn>
		</el-dialog>
		<!-- 选择宠物 -->
		<el-dialog
		class="dialog_one"
		:show-close="false"
		:visible.sync="select_pet_status"
		width="80%"
		top="15vh"
		@close="select_pet_dialog_close"
		>
		<SelectPet v-if="select_pet_status"  @child-click="petHandleChildClick"></SelectPet>
		</el-dialog>
		<!-- 退出登录 -->
		<!-- <el-button class="logoff_but" icon="el-icon-refresh-left" @click="logoff_fun" circle></el-button> -->
    </div>
</template>

<script>
import { getuserlist ,getBaseUserInfo, getUserPetList, addPendingOrder, getPendingOrderCount,getUserInfoByMobile, addpet, getUserCardList} from '@/api/home.js'
import ChooseGoods from './ChooseGoods.vue';
import DrawerPay from './DrawerPay.vue';
import RemoveOrder from './RemoveOrder.vue';
import AddUser from './AddUser.vue';
import UserCard from './UserCard.vue';
import UserCountCard from './UserCountCard.vue';
import GoodsWarn from './GoodsWarn.vue';
import SelectPet from './SelectPet.vue';

export default {
	name: 'HomeCashier',
	props: {
		msg: String
	},
	components: {
		ChooseGoods,
		DrawerPay,
		RemoveOrder,
		AddUser,
		UserCard,
		UserCountCard,
		GoodsWarn,
		SelectPet,
	},
	data() {
		return {
			select_pet_status:false, //选择宠物
			imageUrl: '',
			// 宠物信息
			pet_form:{
				pet_name:'', //宠物名称
				variety:'', //品种
				sex:'1', //性别
				birthday:'2020-02-02', //宠物生日
				color:'', //毛色
				long:'', //肩高/体长
				weight:'', //体重
				is_sterilize:'1', //绝育情况
				pet_img:'', //宠物头像
				note:'', //备注			
				user_id:'', //用户id	
			},
			//会员信息
			user_name_img:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png', //用户头像
			user_name:'张三', //用户名称
			user_mobile:'13888888888', //用户手机号
			user_id:'123456789', //用户id
			user_integral:'100', //用户积分
			total_order_amount:'1000', //用户总消费金额
			user_money:'1000', //用户余额
			last_order_time:'2023-12-12', //用户最后一次消费时间
			average_order_amount:'100', //用户平均消费金额
			order_count:'10', //用户消费次数

			is_user:0, //是否是会员 或散客 0:散客 1:会员
			user_pet_list:[], //用户宠物列表

			dialogVisible: false, //选购弹窗

			//总价
			total_price:0,
			// 优惠金额
			total_discount_price:0,
			// 应付金额
			pay_price:0,
			drawer: false, //抽屉
			user_name_search:'', //搜索用户名称
			num: 1,
			input_search:'',
			currentDate: new Date(),
			activeName:'vip_card',//第一个选项卡的位置
			restaurants: [{
				"value": "三全鲜食（北新泾店）",
				"address": "长宁区新渔路144号"
			},{
				"value": "Hot honey 首尔炸鸡（仙霞路）",
				"address": "上海市长宁区淞虹路661号" 
			},{
				"value": "新旺角茶餐厅",
				"address": "上海市普陀区真北路988号创邑金沙谷6号楼113"
			}], //搜索会员输入建议
			//选中商品数据
			tableData: [],
			list:[],
			vip_card_list:[], //会员卡列表
			pending_order_num:0, //挂单数量
			remove_order_status:false, //解挂弹窗
			add_user:false, //新增会员弹窗
			centerDialogVisible:false,//新增会员后 展示用户信息
			back_user_info:'', //返回的用户信息
			addpet:false, //添加宠物
			user_card_status:false, //办理会员卡
			user_count_card_status:false, //办理次卡
			// 选中的会员卡信息
			active_info:{},
			active_id: '-1', //选中的会员卡id
			user_goods_warn_status:false, //新增提醒
		};
    },
    methods: {

		// 次卡选择 修改金额
		


		// 选择宠物成功后 处理宠物展示信息
		petHandleChildClickInfoS1(){
			let loc_table_data = localStorage.click_goods_list;
			let table_data_parse_data = JSON.parse(loc_table_data);
			console.log('处理宠物信息12',table_data_parse_data.length);
			for (let i = 0; i < table_data_parse_data.length; i++) {
				table_data_parse_data[i]['all_pet_name'] = [];
				let  parse_select_pet_goods_key_id = JSON.parse(table_data_parse_data[i]['select_pet_goods_key_id']);
				// 处理宠物信息
				console.log('处理宠物信息',parse_select_pet_goods_key_id);
				// 获取第一个宠物的名字
				for (let is = 0; is < parse_select_pet_goods_key_id.length; is++) {
					if (is == 0) {
						let pet_name = parse_select_pet_goods_key_id[is]['name'];
						// 获取pet_name 第一个字符
						let pet_name_first = pet_name.substr(0,1);

						table_data_parse_data[i]['pet_name'] = pet_name_first;
					}
					// 获取所有宠物名称 \n 换行
					let all_pet_name = parse_select_pet_goods_key_id[is]['name'];
					let nums = is+1;
					// 将 all_pet_name 拼接成数组
					let all_pet_name_arr = [];
					all_pet_name_arr[is] = all_pet_name;
					// 将 all_pet_name_arr 放到 table_data_parse_data里面

					// table_data_parse_data[i]['all_pet_name'] = `<p>这是动态生成的HTML代码，当前时间是：${new Date().toLocaleString()}</p>`;
					if (is == 0) {
						table_data_parse_data[i]['all_pet_name'] += nums+"."+all_pet_name;
					} else {
						table_data_parse_data[i]['all_pet_name'] += "<br/>"+nums+"."+all_pet_name;
					}

					
				}
			}
			// 将 table_data_parse_data 重新存入 loc_table_data
			localStorage.setItem('click_goods_list',JSON.stringify(table_data_parse_data));

			console.log('处理宠物信息12',table_data_parse_data);
			this.tableData = JSON.parse(loc_table_data);
			// this.total_price_eidt();
		},
		//  选择宠物
		select_pet_click(info, data){
			console.log('选择宠物',info);
			console.log('选择宠物2',data);
			// 存入缓存
			localStorage.setItem('select_pet_goods_id',JSON.stringify(info.goods_id));
			localStorage.setItem('select_pet_goods_key_id',JSON.stringify(data));
			// 获取 当前产品下的宠物列表
			let me_product_localStorage = localStorage.click_goods_list;
			let me_product = JSON.parse(me_product_localStorage);
			// 将 me_product 里面的 select_pet_goods_key_id 写入到缓存 click_pet_goods_list， 先重置缓存click_pet_goods_list 在写入
			// 重置 缓存 click_pet_goods_list，
			localStorage.setItem('click_pet_goods_list',JSON.stringify([]));
			// 将 me_product.data 里面的 select_pet_goods_key_id 写入到缓存 click_pet_goods_list
			// 判断是否 等于 undefined
			// 判断 me_product[data]['select_pet_goods_key_id'] 是否存在
			if (me_product[data]['select_pet_goods_key_id'] == undefined) {
				localStorage.setItem('click_pet_goods_list',JSON.stringify([]));
			} else {
				let click_pet_goods_list_arr = (me_product[data]['select_pet_goods_key_id']);
				localStorage.setItem('click_pet_goods_list',click_pet_goods_list_arr);
			}
			// console.log('购物商品选择：',me_product[data]['select_pet_goods_key_id']);
			this.select_pet_status = true;
		},
		open_user_info(user_id){
			this.$router.push({path:'/user_info/UserInfo/user_id/'+this.user_id});
		},
		// 用户信息
		user_info(user_id=4){
			console.log('---user_info----');
			this.$router.push({path:'/user_info/UserInfo/user_id/'+this.user_id});
		},
		// 档案
		archives_info(pet_id=23) {
			console.log(pet_id);
			// this.$router.push({path:'/archives'});
			this.$router.push({path:'/ArchivesInfo/pet_id/'+pet_id});
			// this.$router.push('/loginpage');
		},
		// 充值
		pay_card(card_id, card_info) {
			console.log('---pay_card----');
			console.log(card_info);
			// 会员卡ID存入缓存
			localStorage.setItem('card_id',card_id);
			localStorage.setItem('card_vip_id',card_info.vip_id);
			this.user_card_status = true;
		},
		// 选中
		vip_card_active(key,active_info) {
			console.log('---vip_card_active----');
			console.log(key);
			console.log(active_info);
			console.log('---vip_card_active--end--');
			// div-card-active
			// 修改 .div-card 里面的 border 样式



			// 删除所有 div-card-active 样式
			let div_card_active = document.getElementsByClassName('div-card-active');
			let div_card_border = document.getElementsByClassName('div-card-border');
			for (let i = 0; i < div_card_active.length; i++) {
				div_card_active[i].classList.remove('div-card-active');
			}
			for (let i = 0; i < div_card_border.length; i++) {
				div_card_border[i].classList.remove('div-card-border');
			}
			if (this.active_id != key) {
				// 添加 div-card-active 样式 div-card-border 
				let div_card = document.getElementsByClassName('div-card');
				let card_date = document.getElementsByClassName('card-date');
				div_card[key].classList.add('div-card-border');
				card_date[key].classList.add('div-card-active');
				// 获取选中的会员卡信息
				this.active_card_info = active_info;
				this.active_id = key;
				console.log('---active_card_info----');
				console.log(this.active_card_info);
				console.log('---active_card_info--end--');
				// 会员卡信息存到缓存里
				localStorage.active_card_info = JSON.stringify(active_info);	
				localStorage.active_card_info_active_key = JSON.stringify(key);	
			} else {
				this.active_card_info = {};
				this.active_id = '-1';
				localStorage.removeItem('active_card_info');
				localStorage.removeItem('active_card_info_active_key');
			}
			

		},
		// 获取会员卡列表
		async getVipCardList(user_id){
			let res = await getUserCardList({ user_id: user_id });
			if (res.status == 200) {
				this.vip_card_list = res.data;
				// 将会员卡列表存入缓存
				localStorage.setItem('vip_card_list',JSON.stringify(res.data));
			} else {
				this.$message({
					message: res.msg,
					type: 'error'
				});
			}
			console.log('----user_res------');
			console.log(this.vip_card_list);
			console.log('----user_res------');
		},
		//添加宠物
		async onsubmit(){
			this.pet_form.user_id = this.user_id;
			// 处理 birthday 时间 Wed Sep 11 2019 00:00:00 GMT+0800 (中国标准时间) 转成 时间戳
			let birthday = this.pet_form.birthday;
			let birthday_time = new Date(birthday).getTime();
			this.pet_form.birthday = birthday_time;
			


			console.log('添加宠物',this.pet_form);
			// return false;
			let res = await addpet(this.pet_form);
			if (res.status == 200) {
				// 初始化 this.pet_form
				this.pet_form.pet_name = '', //宠物名称
				this.pet_form.variety = '', //品种
				this.pet_form.sex = '1', //性别
				this.pet_form.birthday = '2020-02-02', //宠物生日
				this.pet_form.color = '', //毛色
				this.pet_form.long = '', //肩高/体长
				this.pet_form.weight = '', //体重
				this.pet_form.is_sterilize = '1', //绝育情况
				this.pet_form.pet_img = '', //宠物头像
				this.pet_form.note = '', //备注			
				this.pet_form.user_id = '', //用户id	
				this.$message({
					message: res.msg,
					type: 'success'
				});
				//关闭窗口
				this.addpet = false;
				// 刷新宠物列表
				//获取用户宠物数据
				await getUserPetList({ user_id: localStorage.user_id }).then(res => {
					this.user_pet_list = res.data;
				})
			} else {
				this.$message({
					message: res.msg,
					type: 'error'
				});
			}
			console.log(res);
			// this.addpet = false;
		},
		// 图片上传
		handleAvatarSuccess(res, file) {
			console.log(res);
			console.log('-----------');
			console.log(file);
			console.log('-----------');
			this.pet_form.pet_img = res.data.base_url;
			this.imageUrl = URL.createObjectURL(file.raw);
		},
		beforeAvatarUpload(file) {
			// const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
			this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return isLt2M;
		},
		//办理会员卡
		user_card() {
			
		},
		//退出登录
		logoff_fun(){
			this.$confirm('是否要退出登录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				//清空管理员信息
				localStorage.removeItem('cashier_admin');
				//清空token
				localStorage.removeItem('cashier_pay_token');
				this.$message({
					type: 'success',
					message: '操作成功!'
				});
				//跳转到登录页面
				this.$router.push('/loginpage');
			}).catch(() => {
				// this.$message({
				// 	type: 'info',
				// 	message: '已取消删除'
				// });  
			}); 
		},
		//获取当前用户挂单数量
		// 获取所有用户挂单数量
		async get_pending_order_num(){
			//获取缓存里的用户信息, 不能直接 json 否则空数据会报错
			let user_info = localStorage.user_info;
			let user_info_id = 0;
			//判断数据是否为空
			if(user_info){
				//不为空 json化
				user_info = JSON.parse(user_info);
				//获取用户id
				user_info_id = user_info.id;
			}
			let res = await getPendingOrderCount({user_id:user_info_id});
			console.log('获取当前用户挂单数量');
			console.log(res);
			if(res.code == 1){
				this.pending_order_num = res.data;
			}
		},
		//挂单
		async pending_order(){
			//获取缓存里购买商品的数据
			let loc_table_data = localStorage.click_goods_list;
			console.log('商品数据');
			console.log(loc_table_data);
			//判断数据是否为空 或者空数组  为空的话 不能挂单
			//判断是否是空数组或者是否是 undefined 如果是空数组或者undefined 不能挂单
			if(loc_table_data == '[]' || loc_table_data == undefined){
				this.$message({
					message: '请先选择商品',
					type: 'warning'
				});
				return false;
			}
			
			//获取缓存里的用户信息, 不能直接 json 否则空数据会报错
			let user_info = localStorage.user_info;
			let data_obj = {};
			//判断数据是否为空
			if(user_info){
				let user_info_no_json = JSON.parse(user_info);
				data_obj.user_id = user_info_no_json.id;
				//不为空 json化
				user_info = JSON.stringify(user_info);
			} else {
				//为空  用户信息不存在 追加一个id 为0的用户信息
				user_info = {};
				user_info.id = 0;
				
				data_obj.user_id = user_info.id;
			}
			//创建一个数组， 把user_id key=user_id 放到里面
			//对 data_obj json化
			data_obj = JSON.stringify(data_obj);
			console.log(data_obj);

			//提交挂单
			await addPendingOrder({ data_obj: data_obj,loc_table_data:loc_table_data }).then(res => {
				console.log('------00000----------')
				console.log(res)
				console.log('------00000----------')
				if (res.code == 1) {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					//清空缓存
					localStorage.removeItem('click_goods_list');
					//获取选中商品数据
					let loc_table_data = localStorage.click_goods_list;
					this.tableData = [];
					this.get_pending_order_num();
					this.total_price_eidt();
				} else {
					this.$message({
						message: res.msg,
						type: 'error'
					});
				}
				//
				console.log(res);
			})
		},
		//新增会员
		new_user(){
			this.add_user = true;
		},
		//新增会员 回调
		add_user_dialog_close() {
			console.log('新增会员回调')
		},
		// 新增提醒回调
		userGoodsWarnClick(data) {
			this.user_goods_warn_status = false;
			console.log('新增提醒回调');
			console.log(data);
		},
		// 选择宠物回调
		choosePetClick(data) {
			this.select_pet_status = false;
			console.log('选择宠物回调');
			console.log(data);
		},
		
		// 办理次卡回调
		addUserCountCardClick(data) {
			this.user_count_card_status = false;
			// 重新获取会员卡列表
			this.getVipCardList(localStorage.user_id);
			console.log('办理次卡回调');
			console.log(data);
		},
		// 办理会员卡回调
		addUserCardClick(data) {
			this.user_card_status = false;
			// 重新获取会员卡列表
			this.getVipCardList(localStorage.user_id);
			console.log('办理会员卡回调');
			console.log(data);
		},
		//新增会员组件 供子组件调用
		addUserClick(data) {
			this.add_user = false;
			this.centerDialogVisible = true;//打开会员卡办理弹窗
			this.getUserInfoByMobile(data);
			
			console.log('新增会员组件 供子组件调用');
		},
		//解挂
		remove_order(){
			this.remove_order_status = true;
		},
		//解挂回调
		remove_order_dialog_close() {
			console.log('解卦回调')
			// 获取用户缓存数据
			var user_info = localStorage.user_info;
			if (user_info) {
				user_info = JSON.parse(user_info);
				this.show_user(user_info, 2);
			} else {
				this.user_switch();
			}
			//获取选中商品数据
			let loc_table_data = localStorage.click_goods_list;
			if(loc_table_data){
				// 处理数据
				// this.petHandleChildClickInfoS1();
				this.tableData = JSON.parse(loc_table_data);
			}
			this.total_price_eidt();
			//获取当前用户挂单数量
			this.get_pending_order_num();
			// 复购提醒
			this.userGoodsWarnStatus();
		},
		// 解挂组件 供子组件调用
		removeOrderClick(data) {
			this.remove_order_status = false;
			this.get_pending_order_num();
			// 处理数据
			// this.petHandleChildClickInfoS1();
			//获取选中商品数据
			let loc_table_data = localStorage.click_goods_list;
			this.tableData = JSON.parse(loc_table_data);
			this.total_price_eidt();
			// console.log('解挂组件 供子组件调用');
		},
		// 选择宠物自组建调用
		selectPethandleChildClick(data){
			this.select_pet_status = false;
		},
		//供子组件调用
		handleChildClick(data) {
			console.log('商品毁掉:', data)
			// console.log('12312312');
			this.dialogVisible = false;
		},
		//选购商品弹窗
		choose_goods(){
			this.dialogVisible = true;
		},
		// 关闭选择宠物弹窗
		select_pet_dialog_close() {
			console.log('选择宠物回调')
			this.close_pet_data();
		},
		// 回掉
		petHandleChildClick(){
			// console.log('回掉');
			this.select_pet_status = false;
			this.close_pet_data();
		},
		// 关闭选择宠物窗口后数据处理
		close_pet_data(){
			// 获取select_pet_goods_id 和 click_pet_goods_list
			let select_pet_goods_id = localStorage.select_pet_goods_id;
			let click_pet_goods_list = localStorage.click_pet_goods_list;
			let select_pet_goods_key_id = localStorage.select_pet_goods_key_id;
			// 获取 click_goods_list 数据
			let click_goods_list_pet = localStorage.click_goods_list;
			// 获取 click_goods_list_pet 下标 为 select_pet_goods_key_id 值， 添加上 click_pet_goods_list 字段数据
			let click_goods_list_pet_data = JSON.parse(click_goods_list_pet);
			// click_goods_list_pet_data[select_pet_goods_key_id]['select_pet_goods_key_id'] = JSON.stringify(click_pet_goods_list);
			click_goods_list_pet_data[select_pet_goods_key_id]['select_pet_goods_key_id'] = click_pet_goods_list;
			// click_goods_list_pet_data 重新写入 click_goods_list
			localStorage.click_goods_list = JSON.stringify(click_goods_list_pet_data);
			
			// 清空 click_pet_goods_list 缓存， 防止数据错误
			// localStorage.removeItem('click_pet_goods_list');
			this.petHandleChildClickInfoS1();
			console.log('选择数据2：', localStorage.click_goods_list);
		},
		//关闭选购商品弹窗
		dialog_close(){
			console.log('关闭选择商品');
			this.input_search = '';
			//获取选中商品数据
			let loc_table_data = localStorage.click_goods_list;
			if(loc_table_data){
				// 处理数据
				// this.petHandleChildClickInfoS1();
				this.tableData = JSON.parse(loc_table_data);
			}
			this.total_price_eidt();
			// 获取 active_card_info_active_key 缓存数据 根据key 选中会员卡
			let loc_active_card_info_active_key = localStorage.active_card_info_active_key;
			let loc_active_card_info = localStorage.active_card_info;
			// 删除所有 div-card-active 样式
			let div_card_active = document.getElementsByClassName('div-card-active');
			let div_card_border = document.getElementsByClassName('div-card-border');
			for (let i = 0; i < div_card_active.length; i++) {
				div_card_active[i].classList.remove('div-card-active');
			}
			for (let i = 0; i < div_card_border.length; i++) {
				div_card_border[i].classList.remove('div-card-border');
			}
			if (this.active_id != loc_active_card_info_active_key) {
				// 添加 div-card-active 样式 div-card-border 
				let div_card = document.getElementsByClassName('div-card');
				let card_date = document.getElementsByClassName('card-date');
				div_card[loc_active_card_info_active_key].classList.add('div-card-border');
				card_date[loc_active_card_info_active_key].classList.add('div-card-active');
				// 获取选中的会员卡信息
				this.active_card_info = loc_active_card_info;
				this.active_id = loc_active_card_info_active_key;
				console.log('---active_card_info----');
				console.log(this.active_card_info);
				console.log('---active_card_info--end--');
			} else {
				this.active_card_info = {};
				this.active_id = '-1';
				localStorage.removeItem('active_card_info');
				localStorage.removeItem('active_card_info_active_key');
			}

		},
		test_clicka() {
			this.user_count_card_status = false;
			this.user_count_card_status = true;
			// let user_info_s = {};
			// user_info_s.mobile = 18618129525;
			// user_info_s = JSON.stringify(user_info_s);
			// this.getUserInfoByMobile(user_info_s);
			
		},
		//根据手机号获取用户信息 并展示出来
		async getUserInfoByMobile(user_info)
		{
			let guibm_user_info = JSON.parse(user_info)
			let guibm_results = {};
			await getUserInfoByMobile({ mobile: guibm_user_info.mobile }).then(res => {
				guibm_results = res.data.data;
				let view_data_back = {};
				view_data_back.avatar = guibm_results.avatar;
				view_data_back.nickname = guibm_results.nickname;
				view_data_back.mobile = guibm_results.mobile;
				view_data_back.id = guibm_results.id;
				console.log(view_data_back);
				this.show_user(view_data_back);
				// this.back_user_info = JSON.stringify(guibm_results);
			})
			// console.log(guibm_results);
			
		},
		//搜索会员输入建议
		async walkSearch(walkSearch, cb) {
			var results = [];
			const user_search_list = await getuserlist({ user_name: walkSearch }).then(res => {
				results = res.data;
			})
			// 调用 callback 返回建议列表的数据
			// console.log(results);
			cb(results);
		},
		walkSelect(item) {
			// console.log(item.nickname);
			this.user_name_search = item.nickname;
			this.show_user(item);
		},
		//展示会员信息
		async show_user(user_info){
			localStorage.removeItem('active_card_info'); //移除会员卡信息
			var base_user = {};
			console.log('用户信息');
			console.log(user_info.id);
			console.log(user_info);
			//获取用户数据
			await getBaseUserInfo({ user_id: user_info.id }).then(res => {
				base_user = res.data;
			})
			//获取用户宠物数据
			await getUserPetList({ user_id: user_info.id }).then(res => {
				this.user_pet_list = res.data;
				// 将宠物列表里面的第一个宠物放到缓存里
				let pet_one_info = this.user_pet_list[0];
				localStorage.setItem('pet_one_info',JSON.stringify(pet_one_info));

				// console.log('宠物列表',this.user_pet_list);
			})
			// 获取当前用户 会员卡列表
			this.getVipCardList(user_info.id);
			console.log(this.user_pet_list)
			this.user_name_img = user_info.avatar;
			this.user_name = user_info.nickname;
			this.user_mobile = user_info.mobile;
			this.user_id = user_info.id;
			this.user_integral = base_user.user_integral;
			this.total_order_amount = base_user.total_order_amount;
			this.user_money = base_user.user_money;
			this.last_order_time = base_user.last_order_time;
			this.average_order_amount = base_user.average_order_amount;
			this.order_count = base_user.order_count;
			localStorage.user_info = JSON.stringify(base_user);
			localStorage.user_id = user_info.id;
			this.is_user = 1;
			//获取当前用户挂单数量
			this.get_pending_order_num();
		},
		//切换会员 清空数据
		async user_switch() {
			localStorage.removeItem('active_card_info'); //移除会员卡信息
			localStorage.removeItem("user_info");
			localStorage.removeItem("user_id");
			// 清空会员卡列表
			this.vip_card_list = [];

			this.is_user = 0;
			this.user_name_search = '';
			//清空数据
			this.user_pet_list = [];
			this.user_name_img = '';
			this.user_name = '';
			this.user_mobile = '';
			this.user_id = 0;
			this.user_integral = 0;
			this.total_order_amount = 0;
			this.user_money = 0;
			this.last_order_time = '';
			this.average_order_amount = 0;
			this.order_count = 0;
			//获取散客订单数量
			let res_base_user = await getBaseUserInfo({ user_id: 0 })
			console.log(res_base_user);
			//获取当前用户挂单数量
			this.get_pending_order_num();
			this.user_integral = res_base_user.data.user_integral;
			this.total_order_amount = res_base_user.data.total_order_amount;
			this.user_money = res_base_user.data.user_money;
			this.last_order_time = res_base_user.data.last_order_time;
			this.average_order_amount = res_base_user.data.average_order_amount;
			this.order_count = res_base_user.data.order_count;

		},
		change_aa(){
			// 将搜索后的内容存入缓存
			localStorage.input_goods_search = this.input_search;
			//打开选购商品弹窗
			this.dialogVisible = true;
			console.log('123123')
		},
		handleClick(tab, event) {
			console.log(tab);
		},
		delHandleClick(index, row) {
			//删除选中商品
			this.tableData.splice(row, 1);
			// console.log(this.tableData);
			//存储选中商品数据
			localStorage.click_goods_list = JSON.stringify(this.tableData);	
			this.total_price_eidt();	
		},
		// 取消赠送
		cancel_give(info, row) {
			this.tableData[row].is_give = 0;
			info.min_count = info.price * info.goods_num;
			//根据数量获取每件商品的价格
			if (info.goods_num) {
				//计算价格 保留小数点后两位
				info.discount_price = (info.min_count / info.goods_num).toFixed(2);
			}
			//计算 discount_price 占 price 的百分之多少 
			if (info.discount_price && info.price) {
				info.discount = (info.discount_price / info.price * 100).toFixed(1);
			} else {
				info.discount = 0;
			}
			this.tableData[row] = info;
			this.total_price_eidt();
		},

		//赠送商品
		giveHandleClick(info, row) {
			// console.log(info);
			// console.log();
			this.tableData[row].is_give = 1;
			info.min_count = '0.00';
			//根据数量获取每件商品的价格
			if (info.goods_num) {
				//计算价格 保留小数点后两位
				info.discount_price = (info.min_count / info.goods_num).toFixed(2);
			}
			//计算 discount_price 占 price 的百分之多少 
			if (info.discount_price && info.price) {
				info.discount = (info.discount_price / info.price * 100).toFixed(1);
			} else {
				info.discount = 0;
			}



			this.tableData[row] = info;
			this.total_price_eidt();
		},
		//修改金额
		discount_price_eidt(info, row) {
			//计算 discount_price 占 price 的百分之多少 
			if (info.discount_price && info.price) {
				info.discount = (info.discount_price / info.price * 100).toFixed(1);
			} else {
				info.discount = 0;
			}

			//折扣率计算， 判断原价 price 和 优惠后的价格 discount_price 是打了几折
			// if (info.discount_price && info.price) {
			// 	info.discount = (info.discount_price / info.price * 10).toFixed(1);
			// } else {
			// 	info.discount = 0;
			// }
			//根据数量计算价格
			if (info.goods_num) {
				info.min_count = (info.discount_price * info.goods_num).toFixed(2);
			}
			this.tableData[row] = info;
			this.total_price_eidt();
		},
		//修改折扣率
		discount_eidt(info, row) {
			//计算 price 的百分之 discount 是多少
			if (info.discount && info.price) {
				info.discount_price = (info.price * info.discount / 100).toFixed(2);
			} else {
				info.discount_price = 0;
			}
			



			//零售价计算，判断原价 price 和 discount 折扣率 后的价格
			// if (info.discount && info.price) {
			// 	info.discount_price = (info.price * info.discount / 10).toFixed(2);
			// } else {
			// 	info.discount_price = 0;
			// }
			//根据数量计算价格
			if (info.goods_num) {
				info.min_count = (info.discount_price * info.goods_num).toFixed(2);
			}
			this.tableData[row] = info;
			this.total_price_eidt();
		},
		//修改数量
		handleChange(info, row) {
			//根据数量计算价格
			if (info.goods_num) {
				//计算价格 保留小数点后两位
				info.min_count = (info.discount_price * info.goods_num).toFixed(2);
				// info.min_count = info.discount_price * info.goods_num;
			}
			this.tableData[row] = info;
			this.total_price_eidt();
		},
		//修改小计
		min_count_eidt(info, row) {
			//根据数量获取每件商品的价格
			if (info.goods_num) {
				//计算价格 保留小数点后两位
				info.discount_price = (info.min_count / info.goods_num).toFixed(2);
			}
			//计算 discount_price 占 price 的百分之多少 
			if (info.discount_price && info.price) {
				info.discount = (info.discount_price / info.price * 100).toFixed(1);
			} else {
				info.discount = 0;
			}


			this.tableData[row] = info;
			this.total_price_eidt();
		},
		//计算总金额
		total_price_eidt() {
			//获取购买商品列表
			let tableData = this.tableData;
			//计算总金额 price、应付金额discount_price、总优惠金额 保留小数点后两位
			let total_price = 0; //总金额
			let pay_price = 0; //应付金额
			let total_discount_price = 0; //总优惠金额
			//循环计算 保留小数点后两位
			for (let i = 0; i < tableData.length; i++) {
				total_price += parseFloat(tableData[i].price) * parseFloat(tableData[i].goods_num);
				pay_price += parseFloat(tableData[i].discount_price) * parseFloat(tableData[i].goods_num);
				total_discount_price += (parseFloat(tableData[i].price) - parseFloat(tableData[i].discount_price)) * parseFloat(
					tableData[i].goods_num);
			}
			this.total_price = total_price.toFixed(2);
			this.pay_price = pay_price.toFixed(2);
			this.total_discount_price = total_discount_price.toFixed(2);
			//保存选中商品数据
			localStorage.click_goods_list = JSON.stringify(tableData);			
		},
		// 跳转到 登录页面
		user_login() {
			//获取 url 里面的 token参数
			let token = this.$route.query.token;
			if (token) {
				//保存 token 到缓存
				localStorage.cashier_pay_token = token;
			}
			

			//获取管理员token 是否存在, 如果不存在则跳转到登录页面
			let cashier_pay_token = localStorage.cashier_pay_token;
			if (!cashier_pay_token) {
				this.$router.push({
					path: '/loginpage',
					// `/describe/`
				})
			}
			// this.$router.push({
			// 	path: '/loginpage',
			// 	// `/describe/`
			// })
		},
		// 复购提醒
		userGoodsWarnStatus(){
			console.log('复购提醒');
			// 获取缓存中复购数据
			let warn_goods_list = localStorage.warn_goods_list;
			console.log('复购提醒-----1');
			// console.log(warn_goods_list.length);
			console.log('复购提醒-----2');
			// 判断数据是否为空
			if (warn_goods_list) {
				// 打开复购页面
				this.user_goods_warn_status = true;
			} else {
				// 关闭复购页面
				this.user_goods_warn_status = false;
			}
			// console.log(warn_goods_list);
			// console.log('---------');
		},
    },
	//初始化加载 页面第一次加载时调用
	mounted() {
		// this.petHandleChildClickInfoS1();
		this.user_login();
		// const router = new VueRouter({
		// 	routes: [
		// 		{ path: '/', component: User }
		// 	]
		// });
		// 获取用户缓存数据
		var user_info = localStorage.user_info;
		if (user_info) {
			user_info = JSON.parse(user_info);
			this.show_user(user_info, 2);
		} else {
			this.user_switch();
		}
		//获取选中商品数据
		let loc_table_data = localStorage.click_goods_list;
		if(loc_table_data){
			// 处理数据
			// this.petHandleChildClickInfoS1();
			this.tableData = JSON.parse(loc_table_data);
		}
		this.total_price_eidt();
		//获取当前用户挂单数量
		this.get_pending_order_num();
		// 复购提醒
		this.userGoodsWarnStatus();

		
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.vip_card_list_class::-webkit-scrollbar {
  width: 0; /* 隐藏浏览器默认的滚动条 */
}
.clbtext-overflow{
	width: 80px;
	word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.card-date{
	position: absolute;
    width: 100%;
    height: 26px;
    line-height: 26px;
    background: #fff;
    bottom: 0;
    border-radius: 0 0 10px 10px;
    text-indent: 6px;
    font-size: 10px;
    color: #272727;
	display: flex;
    justify-content: space-between;
}

.card-price>span:first-child span {
	width: 120px;
    height: 37px;
    font-size: 26px;
    color: #fff;
    line-height: 32px;
}
.card-price>span:first-child{
	width: 17px;
    height: 20px;
    margin-left: 15px;
    font-size: 14px;
    line-height: 18px;
}
.card-price{
	position: absolute;
    bottom: 26px;
    width: 100%;
    height: 37px;
    color: #fff;
}
.div-card-p{
	margin: 16px 0 0 15px;
    color: #fff;
    line-height: 20px;
    height: 20px;
}
.div-card-active{
	background:#45b787;
	color: #ffffff;
}
.div-card-border{
	border: 2px solid #45b787;
}
.div-card{
	position: relative;
    display: inline-block;
    width: 90%;
    height: 156px;
    background-size: 300px 130px;
    background-repeat: no-repeat;
    box-shadow: 0 6px 16px 0 hsla(0,0%,83.5%,.5019607843);
    border-radius: 13px;
    border: 2px solid #d5d5d5;
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;
	margin: 2.5% 0 0 4%;
}


.logoff_but{
	background: #45b787;
	color: #ffffff;
	position: absolute;
    z-index: 1;
    left: 0%;
    top: 50%;
}
.el-table__row .el-table__cell .cell .el-avatar img{
	width: 100%;
}
.dialog_one .el-dialog{
	background: transparent;
}
.left-top-two{
	margin: 0 0%;
	height: 120px;
	background: #ffffff;
	border-radius: 15px;
}
.left-top-two .el-input__inner {
	border-radius: 20px;
}
.left-top-two .el-input__inner:hover{
	border-color: #45b787;
}
.goods-left{
	margin-left:2%;
	background-color: #ffffff;
	width: 98%;
	height: calc(100% - 372px);
	/* float: left; */
	box-sizing: border-box;
}
.goods-one{
	margin-bottom: 15px;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
}
.goods-bottom-two{
	float: right;
	display: inline-block;
}
.goods-bottom{
	/* width: 98%; */
	margin-left: 2%;
	height: 71px;
    line-height: 71px;
    padding: 0 12px 0 12px;
    /* margin-bottom: 40px; */
    background: #fff;
    border-radius: 12px;
    z-index: 9;
    position: relative;
}
.pet-text p:last-child{
	margin-top: 9px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #c1c4c7;
    line-height: 17px;
}
.pet-text p:first-child span{
	font-size: 10px;
    background-color: #45b787;
    border-radius: 10px;
    padding: 1px 6px;
    margin-left: 6px;
	color: #ffffff;
}
.pet-text p:first-child{
	margin: 0;
	height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #39393c;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
}
.pet-text{
	display: inline-block;
    height: 46px;
    width: calc(100% - 61px);
    vertical-align: top;
    margin-left: 15px;
}
.el-input-number--mini{
	width: 100%;
}
.el-input-group__append{
	padding: 0 5px;
}

.el-tabs--card>.el-tabs__header{
	/* border-bottom: 0; */
	border-radius: 30px;
}
.el-tabs--card>.el-tabs__header .el-tabs__item{
	border-left: 0;
	border-bottom:0;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav{
	border-radius: 30px;
	border-bottom:1;
	/* border:0; */
}
.el-tabs__item:hover {
    color: #45b787; 
    cursor: pointer; 
  }
.el-tabs__item.is-active {
    color: #ffffff;  
	background-color: #45b787;
  }
.el-tabs__item{
	/* padding: 0; */
	border-radius: 30px;
}
.el-tabs__nav{
	
}
.demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
.goods-list{
	height: 85vh;
	overflow-y:
	auto;flex:1;
	padding-left: 2%;
}
  .goods-list .time {
    font-size: 13px;
    color: #999;
  }
  
  .goods-list .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .goods-list .button {
    padding: 0;
    float: right;
  }

  .goods-list .image {
    width: 100%;
    display: block;
	padding: 0px;
  }

  .goods-list .clearfix:before,
  .goods-list .clearfix:after {
      display: table;
      content: "";
  }
  
  .goods-list .clearfix:after {
      clear: both
  }
.goods-top{
	height: 30px;
}
.goods-top-bu{
	margin-right: 5px;
	float: left;
	text-align: center;
	width: 100px;
	height: 30px;
	line-height: 30px;
	border: 1px solid rgba(0,0,0,.2);
	cursor: pointer;
}
.goods-top-search{
	margin-right: 5px;
	float: left;
	width: 300px;
	height: 30px;
	/* line-height: 30px; */
	/* border: 1px solid rgba(0,0,0,.2); */
	cursor: pointer;
}
.function_one{
	cursor: pointer;
	width: 80%;
	margin: 0 10%;
	height: 30px;
	text-align: center;
	border: 1px solid rgba(0,0,0,.2);
	line-height: 30px;
	margin-bottom: 20px;
	color: #606266!important;
}
/* .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  } */
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  #app .el-drawer__wrapper .el-drawer__container .el-drawer{
	width: 548px!important;
	overflow-y: auto;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  body{
	font-size:14px;
  }
</style>
