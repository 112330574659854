<template>
    <div id="app">
		<div class="choosegoods-one">
			<el-tabs class="choose_goods_one" v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="商品列表" name="goods_first">
					<el-container>
						<el-aside width="25%">
							<div class="cg-box-left">
								<el-tree :data="category_list" :props="defaultProps"  :current-node-key="0" node-key="id" highlight-current @node-click="handleNodeClick"></el-tree>
							</div>
						</el-aside>
						<el-main style="padding: 0;">
							<div class="cg-box-right">
								<div class="goods-search">
									<div style="width: 40%; margin-right:20px ;float: left;">
										<el-input
										placeholder="请输入商品名称"
										v-model="search_name"
										clearable
										@clear="clear_name"
										>
										</el-input>
									</div>
									<el-button type="primary" round style="background-color: #45b787;"  v-on:click="searchGoodsList()">搜索</el-button>
								</div>
								<div class="ch-goods-list">
									<!-- 选中类 ch-active -->
									<div class="ch-goods-info"  v-for="(item, index) in goods_list" :key="item.id" v-on:click="goods_active(index,item)"  :goods_id="item.id">
										<el-avatar style="height: 88px; width: 88px; margin: 15px 0 0 12px;" :src="item.image"></el-avatar>
										<div class="ch-goods-info-title">
											<p>{{ item.name }} </p>
											<p>
												<span>{{ item.code }}</span>
												<span>{{ item.stock }}</span>
											</p>
											<p>
												<span>会员价 <b>¥ {{ item.min_price }}</b></span>
												<span>零售价 <b>¥ {{ item.market_price }}</b></span>
											</p>
										</div>
									</div>
								</div>
								<!-- 提交区域 -->
								<div class="ch-sub">
									<div class="ch-sub-text_a">
										<el-tag
										v-for="tag in goods_tags"
										:key="tag.name"
										color="#45b787"
										style="margin-right: 10px; color: #ffffff; margin: 9px 10px 0 0;"
										closable
										:type="tag.type">
										{{tag.name}}
										</el-tag>
									</div>
									<el-button round class="ch-but_a" style="pointer-events: auto; opacity: 1;"  @click="sub_click">提交</el-button>
								</div>
								<!-- 分页 -->
								<div class="ch-page">
									<el-pagination
									background
									class="ch-page-list"
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page="currentPage"
									:page-size="10"
									:pager-count="7"
									layout="total, prev, pager, next, jumper"
									:total="goods_total">
									</el-pagination>
								</div>
							</div>
						</el-main>
					</el-container>
				</el-tab-pane>
			</el-tabs>
		</div>
    </div>
</template>

<script>
import { getGoodsCategoryList, getGoodsList} from '@/api/choose_goods.js'
export default {
	name: 'ChooseGoods',
	props: {
		msg: String
	},
	data() {
		return {
			goods_total: 0,//商品总数
			currentPage: 1, // 当前页
			page_num:1,//页码
			search_name: '',//搜索商品名称
			activeName: 'goods_first',
			category_list: [],//
			defaultProps: {
				children: 'children',
				label: 'label'
			},
			//标签
			goods_tags: [
				{ name: '标签一AAAAAsqsws', type: '' },
				{ name: '标签二', type: 'success' },
				{ name: '标签三', type: 'info' },
				{ name: '标签五', type: 'danger' }
			,],
			//商品列表
			goods_list:[],
		};
    },
    methods: {
		sub_click()
		{
			this.$emit('child-click', 'hello');
		},
		goods_active(key,active_goods_info, type = 1)
		{
			console.log(active_goods_info);
			let goods_id = active_goods_info.id;
			//写入点击数据的缓存
			//创建一个空数组
			let click_goods_list = [];
			//获取缓存数据
			let loc_click_goods_list = localStorage.click_goods_list;

			// 获取缓存里的宠物信息
			let pet_one_info = JSON.parse(localStorage.pet_one_info);
			let pet_name_one = pet_one_info.pet_name;
			console.log('宠物信息',pet_one_info);
			// 获取第一个字符
			let pet_name_first = pet_name_one.substr(0,1);
			let all_pet_name_one = pet_one_info.pet_name;
			// 创建一个数组 将 pet_one_info 里面的 pet_name 和 id 追加到这个数组里面， 这个数组是一个二维数组


			
			let select_pet_goods_arr = new Array();
			let sekect_pet_one = {
				goods_id : pet_one_info.id,
				name : pet_one_info.pet_name,
				photo : pet_one_info.photo,
				variety : pet_one_info.variety
			};
			// console.log('第一个宠物信息',sekect_pet_one);
			select_pet_goods_arr.push(sekect_pet_one);

			console.log('第一个宠物信息',JSON.stringify(select_pet_goods_arr));
			// console.log('-----AAAAABBBB--------');
			// console.log(key);
			// console.log('-----AAAAAABBBB--------');
			//创建一个对象 里面有 goods_name 字段
			let loc_goods_info = {
				goods_id:goods_id,
				name:active_goods_info.name,
				thumbnail:active_goods_info.image,
				price:active_goods_info.min_price,
				discount_price:active_goods_info.min_price,
				discount:100,
				goods_num:1,
				min_count:active_goods_info.min_price,
				category:active_goods_info.name,
				is_give:0,
				select_pet_goods_key_id:JSON.stringify(select_pet_goods_arr),
				pet_name:pet_name_first,
				all_pet_name:all_pet_name_one,
			};
			//判断缓存是否存在 如果存在 就把缓存数据追加到数组中, 如果不存在 就把当前点击的数据写到空数组里
			if (loc_click_goods_list) {
				//遍历缓存数据 查询是否有重复数据
				loc_click_goods_list = JSON.parse(loc_click_goods_list);
				var is_repeat = 0;
				if (type == 1) {
					for (let index = 0; index < loc_click_goods_list.length; index++) {
						//判断是否有重复数据 如果有重复数据 停止执行该方法
						if (loc_click_goods_list[index].goods_id == goods_id ) {
							console.log('数据重复-停止执行')
							is_repeat = 1;
							//删除重复数据
							loc_click_goods_list.splice(index, 1);
							//重排序
							loc_click_goods_list.sort();
							//删除样式
							let goods_info = document.getElementsByClassName('ch-goods-info');
							//删除 ch-active 样式
							goods_info[key].classList.remove('ch-active');
						}
					}
				} else {
					//搜索进入 且只有一条搜索记录
					for (let index = 0; index < loc_click_goods_list.length; index++) {
						//判断是否有重复数据 如果有重复数据 停止执行该方法
						if (loc_click_goods_list[index].goods_id == goods_id ) {
							loc_click_goods_list[index].goods_num++;
							console.log('数据重复-停止执行')
							console.log(loc_click_goods_list[index].goods_num);
							is_repeat = 1;
						}
						console.log('数据重复-停止执行=012')
						console.log(loc_click_goods_list);
						localStorage.click_goods_list = JSON.stringify(loc_click_goods_list);
					}
				}
				
				if (is_repeat == 0) {
					// console.log('-----BBBB--------');
					// console.log(loc_click_goods_list)
					// console.log('------BBBB-------');
					//追加数据
					loc_click_goods_list.push(loc_goods_info);
				}
			} else {
				//把当前点击的数据写到空数组里
				loc_click_goods_list = [];
				loc_click_goods_list.push(loc_goods_info);
				// loc_click_goods_list = loc_goods_info;
				
			}
			//写入缓存
			localStorage.click_goods_list = JSON.stringify(loc_click_goods_list);
			//修改标签
			this.goods_tags = loc_click_goods_list;

			console.log(loc_click_goods_list);
			//获取当前class 的 ch-goods-info 的样式
			// let goods_info = document.getElementsByClassName('ch-goods-info');
			// console.log(goods_info);
			//清空所有的class
			// for (let i = 0; i < goods_info.length; i++) {
			// 	goods_info[i].classList.remove('ch-active');
			// }
			//给当前点击的class 添加class
			if (is_repeat == 0 && type == 1) {
				let goods_active = document.getElementsByClassName('ch-goods-info');
				console.log(goods_active);
				goods_active[key].classList.add('ch-active');
			}
			// 计算 loc_click_goods_list 里面有几条数据
			let loc_click_goods_list_length = loc_click_goods_list.length;
			// console.log('点击的数据条数',loc_click_goods_list);
			// 如果只有一条 判断该条记录是否是可以使用该会员的次卡
			if (loc_click_goods_list_length == 1) {
				// 获取缓存里的会员卡列表
				let loc_vip_card_list = JSON.parse(localStorage.vip_card_list);
				// loc_click_goods_list 里面的goods_id 是否和 loc_vip_card_list 里面的 service_id 相同
				let is_vip_card = 0;
				for (let index = 0; index < loc_vip_card_list.length; index++) {
					if (loc_vip_card_list[index].service_id == loc_click_goods_list[0].goods_id) {
						// is_vip_card = 1;
						// 如果相同 吧该会员卡的信息写入缓存
						let vip_card_info = {
							id:loc_vip_card_list[index].id,
							background_image:loc_vip_card_list[index].background_image,
							balance:loc_vip_card_list[index].balance,
							giving:loc_vip_card_list[index].giving,
							is_integral:loc_vip_card_list[index].is_integral,
							is_integral_name:loc_vip_card_list[index].is_integral_name,
							name:loc_vip_card_list[index].name,
							pay_price:loc_vip_card_list[index].pay_price,
							price:loc_vip_card_list[index].price,
							remain_num:loc_vip_card_list[index].remain_num,
							remark:loc_vip_card_list[index].remark,
							service_id:loc_vip_card_list[index].service_id,
							service_name:loc_vip_card_list[index].service_name,
							status:loc_vip_card_list[index].status,
							status_name:loc_vip_card_list[index].status_name,
							type:loc_vip_card_list[index].type,
							vip_end_time:loc_vip_card_list[index].vip_end_time,
							vip_id:loc_vip_card_list[index].vip_id,
							vip_start_time:loc_vip_card_list[index].vip_start_time,
							vip_time:loc_vip_card_list[index].vip_time,
							vip_type:loc_vip_card_list[index].vip_type
						}
						// 写入缓存
						localStorage.active_card_info = JSON.stringify(vip_card_info);
						localStorage.active_card_info_active_key = JSON.stringify(index);
					}
				}
			}
			
		},
		//清空搜索框
		clear_name() {
			this.setListClass();
			this.search_name = '';
			this.getGoodsList();
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			//分页
			this.page_num = val;
			this.getGoodsList();
		},
		handleNodeClick(data) {
			console.log(data);
			let ca_type = 1;
			//判断是几级分类
			if (data.level == 1) {
				//一级分类
				ca_type = 1;
			} else if (data.level == 2) {
				// 二级分类
				ca_type = 2;
			}
			this.getGoodsList(data.id, ca_type);
		},
		//获取商品分类列表
		async getGoodsCategoryList(){
			let res = await getGoodsCategoryList();
			console.log(res.status);
			if(res.status == 200){
				this.category_list = res.data;
			}
		},
		//搜索获取商品列表
		async searchGoodsList(){
			
			// 判断搜索内容是否为空
			// if(this.search_name == ''){
			// 	this.$message({
			// 		message: '请输入商品名称',
			// 		type: 'warning'
			// 	});
			// 	return false;
			// }
			this.getGoodsList(0, 1);
		},
		//获取商品列表
		async getGoodsList(category_id, type){
			// 获取缓存搜索内容
			let input_goods_search = localStorage.input_goods_search;
			// 判断缓存搜索内容是否为空
			if(input_goods_search != undefined){
				// 判断搜索内容是否为空
				this.search_name = input_goods_search;
				// 清空缓存
				localStorage.removeItem('input_goods_search');
			}
			let res = await getGoodsList({category_id: category_id, type: type, p: this.page_num, search_name: this.search_name});
			console.log(res);
			if(res.status == 200){
				this.goods_list = res.data['list'];
				this.goods_total = res.data['total'];
				// 如果搜索出来只有一个数据 就直接加入选中缓存里面
				if (this.goods_list.length == 1) {
					this.goods_active(0, this.goods_list[0], 2);
					// 关闭窗口
					this.$emit('child-click', 'hello');
				}
				console.log('----count---');
				console.log(this.goods_list);
				console.log(this.goods_list.length);
			}
			this.setListClass();
		},
		//获取商品缓存
		getGoodsTags(){
			//获取缓存数据
			let loc_click_goods_list = localStorage.click_goods_list;
			//判断是否有缓存数据
			if (loc_click_goods_list == undefined) {
				return false;
			}
			this.goods_tags = JSON.parse(loc_click_goods_list);

		},
		//根据选中的商品列表增加样式
		setListClass(){
			setTimeout(function() {
					//获取缓存数据
					let loc_click_goods_list = localStorage.click_goods_list;
					//判断是否有缓存数据
					if (loc_click_goods_list == undefined) {
						return false;
					}
					let click_goods_list = JSON.parse(loc_click_goods_list);
					//获取当前class 的 ch-goods-info 的样式
					let goods_info = document.getElementsByClassName('ch-goods-info');
					console.log(click_goods_list);
					console.log('---------');
					
					//选中样式
					for (let i = 0; i < goods_info.length; i++) {
						for (let j = 0; j < click_goods_list.length; j++) {
							if (goods_info[i].getAttribute('goods_id') == click_goods_list[j].goods_id) {
								goods_info[i].classList.add('ch-active');
							}
						}
					}
					// for (let i = 0; i < goods_info.length; i++) {
					// 	let goods_for_id = goods_info[1].getAttribute('goods_id');
					// 	goods_info[i].classList.add('ch-active');
					// }
				}, 500)
			//获取缓存数据
			// let loc_click_goods_list = localStorage.click_goods_list;
			// let click_goods_list = JSON.parse(loc_click_goods_list);
			// //获取当前class 的 ch-goods-info 的样式
			// let goods_info = document.getElementsByClassName('ch-goods-info');
			// console.log(goods_info);
			// console.log(goods_infos.length);




						
			

			// console.log('---------');
			// for (let i = 0; i < goods_info.length; i++) {
			// 	console.log('123123');
			// 	// goods_info[i].classList.remove('ch-active');
			// 	let goods_id = goods_info[i].getAttribute('goods_id');
			// 	console.log(goods_id);
			// }
			// // let goods_id = goods_info.getAttribute('goods_id');
			// console.log('123123');
		},
		
    },
	//初始化加载 页面第一次加载时调用
	mounted() {
		console.log(';12312313311111')
		this.getGoodsCategoryList();
		this.getGoodsList(0, 1); 
		this.getGoodsTags();
		// this.setListClass();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-tree-node.is-current > .el-tree-node__content {
	background-color: #45b787 !important;
	color: #fff !important;
}


.ch-page .ch-page-list .btn-prev:hover{
	border:1px solid #45b787;
	color: #45b787;
	border-radius: 5px;
}
.ch-page .ch-page-list .btn-prev{
	background: #ffffff;
	border-radius: 5px;
}
.ch-page .ch-page-list .btn-next:hover{
	border:1px solid #45b787;
	color: #45b787;
	border-radius: 5px;
}
.ch-page .ch-page-list .btn-next{
	background: #ffffff;
	border-radius: 5px;
	/* color: #fff; */
}

.ch-page .ch-page-list .el-pager .number{
	border-radius: 5px;
	/* background: #45b787;
	color: #fff; */
}
.ch-page .ch-page-list .el-pager li:not(.disabled).active {
	border-radius: 5px;
	background: #45b787;
	color: #fff;
}
.ch-page-list{
	float: right;
}
.ch-page{
	margin-top: 20px;
}
.ch-but_a{
	position: absolute;
    width: 108px;
    margin-right: 0;
    top: 26px;
    right: 20px;
	background: #45b787;
	color: #fff;
}
.ch-sub-text_a .el-tag .el-tag__close{
	color: #333336;
}
.ch-sub-text_a .el-tag .el-tag__close:hover{
	background: #333336;
	color: #fff;
}
.ch-sub-text_a{
	position: relative;
    padding: 0 148px 10px 20px;
    margin-top: 4px;
    height: 82px;
    overflow-y: auto;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
}
.ch-sub{
	position: relative;
}
.ch-goods-info .el-avatar>img{
	width: 100%;
}
.ch-goods-info-title>p:first-child{
	margin-top: 0;
	margin-bottom: 0;
	height: 37px;
    font-size: 14px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ch-goods-info-title>p:nth-child(2){
	font-size: 12px;
    margin: 10px 0 7px 0;
    display: flex;
    justify-content: space-between;
    height: 20px;
    overflow: hidden;
}
.ch-goods-info-title>p:nth-child(3){
	font-size: 12px;
    margin: 10px 0 7px 0;
    display: flex;
    justify-content: space-between;
    height: 20px;
    overflow: hidden;
}
.ch-goods-info-title{
	color: #333336;
    display: inline-block;
    width: calc(100% - 133px);
    margin: 14px;
    vertical-align: top;
}
.ch-goods-list{
	margin-top: 30px;
	overflow-y: auto;
    height: 390px;
}
.ch-goods-list .ch-active{
	background:#45b787;
	color: #ffffff;
}
.ch-goods-list .ch-active .ch-goods-info-title{
	color: #ffffff;
}

.ch-goods-info:hover{
	border: 1px solid #45b787;
}
.ch-goods-info{
	display: inline-block;
    width: calc(40% - 10px);
    height: 118px;
    border-radius: 6px;
    background: #fff;
    margin-left: 15px;
    margin-bottom: 10px;
    border: 1px solid #fff;
    cursor: pointer;
}
.choosegoods-one .choose_goods_one .el-tabs__content .el-tab-pane .el-container{
	height: calc(100% - 40px);
}
.cg-box-right{
	border-radius: 0 8px 8px 0;
    background: #f9f9f9;
    /* width: 100%; */
    padding: 20px 20px;
    overflow-y: auto;
    height: calc(100% - 40px);
}
.cg-box-left .el-tree .el-tree-node{
	padding: 5px 0 0 0;
}
.cg-box-left{

	/* width: 171px; */
    padding: 0 18px;
    /* overflow-y: auto; */
    /* height: 100%; */
}
.clb-dialog-box{
	height: 638px;
	overflow-y: auto;
}
.el-dialog__body{
	padding: 0px;
}
.el-dialog__header{
	padding: 0px;
}
.el-tabs--card>.el-tabs__header{
	background: transparent;
}

.choosegoods-one .choose_goods_one .el-tabs__header{
	border-radius: 0;
	border: 0;
	margin: 0;
}
.choosegoods-one .choose_goods_one .el-tabs__content{
	background: #ffffff;
}
.choosegoods-one .choose_goods_one .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav {
	border: 0;
}
.choosegoods-one .choose_goods_one .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__item {
	border-radius:0px;
}
</style>
