<template>
	<div id="app">
		<div class="pet_login">
			<div class="pet_login_content">
				<div class="pet_login_content_info">
					<div class="pet_login_content_info_title">
						<img src="../assets/logo.png" alt="">
					</div>
					<el-input placeholder="请输入用户名" v-model="username"></el-input>
					<el-input style="margin-top: 12px;" placeholder="请输入密码" v-model="password" show-password></el-input>
				</div>
				<div class="login-div-button">
					<el-button class="login-button" round  v-on:click="login_cashier()">登录</el-button>
				</div>
				
			</div>
			<div class="demo-image__placeholder">
				<div class="block">
					<el-image :src="src"></el-image>
				</div>
			</div>
		</div>
	</div>
  </template>
  
<script>
import { loginCashier } from '@/api/home.js'
  export default {
		name: 'LoginPage',
		props: {
			msg: String
		},
		data() {
			return {
				username: '',
				password: '',
				src: 'http://qiniuyk.webe.asia/image/doctor.jpg'
			}
		},
		methods: {
			//获取当前用户挂单数量
			async login_cashier(){
				
				let res = await loginCashier({username:this.username,password:this.password});
				console.log('获取当前用户用户');
				console.log(res);
				if(res.status != 200){
					//提示错误
					this.$message({
						message: res.msg,
						type: 'error'
					});
				} else {
					//保存员工信息 	到缓存
					localStorage.setItem('cashier_admin', JSON.stringify(res.data));
					//保存token		到缓存
					localStorage.setItem('cashier_pay_token', res.data.token);
					//跳转到首页
					this.$router.push({
						name: 'HomeCashier',
						params: {}
					});
				}
			}
		}
	}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pet_login_content_info_title{
	margin-left: 15%;
}
.pet_login_content .login-div-button .login-button{
	width: 100%;
	height: 40px;
	/* line-height: 40px; */
	border-radius: 20px;
	background: #45b787;
	border: 1px solid #45b787;
	color: #ffffff;
}
.pet_login_content .login-div-button{
	margin-left: 2.5%;
	width: 95%;
	text-align: center;
	border-radius: 20px;
    background: #45b787;
    border: 1px solid #45b787;
    color: #ffffff;
}
.pet_login_content .login-div-button :hover{
	border-radius: 20px;
    background: #237754;
    border: 1px solid #237754;
    color: #ffffff;
}
.pet_login .pet_login_content .pet_login_content_info {
	width: 100%;
    max-width: 300px;
    /* background-color: #fff; */
    padding: 1rem 1.2rem;
    border-radius: 0.4rem;
    z-index: 999;
    transition: all .3s;
}
.pet_login .pet_login_content {
	position: absolute;
    z-index: 1;
    right: 10%;
    top: 25%;
}
</style>
  