<template>
    <div id="app">
        <el-form :model="card_form" :rules="rules" ref="card_form" label-width="100px" class="demo-card_form">
            <el-form-item label="选择开通项目">
                <el-select :disabled="select_disabled_one" v-model="card_form.user_vip_id" placeholder="请选择开通项目">
                    <el-option  v-for="item in card_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="支付方式">
                <el-select  v-model="card_form.pay_type" placeholder="请选择支付方式">
                    <el-option label="微信支付" value="1"></el-option>
                    <el-option label="支付宝支付" value="2"></el-option>
                    <el-option label="现金支付" value="3"></el-option>
                    <el-option label="POS机" value="4"></el-option>
                    <el-option label="其它" value="5"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="支付金额">
                <el-input type="number" placeholder="请输入支付金额" v-model="card_form.user_pay_price" style="width: 46%;">
                    <template  slot="append">&nbsp;&nbsp;&nbsp;元&nbsp;&nbsp;&nbsp;</template>
                </el-input>
            </el-form-item>
            <el-form-item label="赠送金额">
                <el-input type="number" placeholder="请输入赠送金额" v-model="card_form.price" style="width: 46%;">
                    <template  slot="append">&nbsp;&nbsp;&nbsp;元&nbsp;&nbsp;&nbsp;</template>
                </el-input>
            </el-form-item>
            <el-form-item label="收银员">
                <el-select  v-model="card_form.admin_user_id" placeholder="请选择收银员">
                    <el-option  v-for="item in admin_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="card_form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :disabled="select_disabled_button" @click="submitForm('card_form')">保存</el-button>
                <el-button @click="resetForm('card_form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { getMemberCardBaseInfo , saveMemberCard} from '@/api/home.js'
    export default {
        name: 'UserCard',
        props: {
            msg: String
        },
        data() {
            return {
                // 会员卡列表 增加默认值
                select_disabled_button: false,
                card_list: [],
                select_disabled_one: false,
                //收银员列表
                admin_list: [],
                card_form: {
                    card_id: 0,
                    user_vip_id: '',
                    pay_type: '1',
                    user_pay_price: '',
                    price: '',
                    admin_user_id: '',
                    remark: '',
                },
                rules: {
                    user_vip_id: [
                        { user_vip_id: true, message: '请输入开通项目', trigger: 'blur' },
                    ]
                }
            }
        },
        methods: {
            //获取办理会员卡基础信息
            async getMemberCardBaseInfo() {
                await getMemberCardBaseInfo().then(res => {
                    console.log(res);
                    if (res.status == 200) {
                        this.card_list = res.data.user_level_list;
                        this.admin_list = res.data.admin_list;
                        //默认会员卡ID
                        this.card_form.user_vip_id = res.data.user_level_list[0].id;
                        console.log(this.card_form.user_vip_id);
                        console.log('---card11111-----')
                        //默认收银员ID
                        this.card_form.admin_user_id = res.data.admin_list[0].id;
                        // 获取是否存在会员卡缓存ID
                        let card_id = localStorage.getItem('card_id');
                        let card_vip_id = localStorage.getItem('card_vip_id');
                        if(card_id && card_vip_id){
                            this.card_form.card_id = card_id;
                            this.card_form.user_vip_id = parseInt(card_vip_id);
                            this.select_disabled_one = true;
                            //清除缓存
                            localStorage.removeItem('card_id');
                            localStorage.removeItem('card_vip_id');
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },


            async submitForm(formName) {       
                // 点击后 5秒钟内不可再次点击
                this.select_disabled_button = true;
                setTimeout(() => {
                    this.select_disabled_button = false;
                }, 10000);


                
                //创建一个对象 将表单数据添加到数组中
                let data_obj = {};
                //当前用户ID
                data_obj.user_id = localStorage.getItem('user_id');
                data_obj.type = 1; //1：会员卡 2:次卡
                data_obj.vip_id = this.card_form.user_vip_id;
                data_obj.pay_price = this.card_form.price;
                data_obj.pay_type = this.card_form.pay_type;
                data_obj.user_pay_price = this.card_form.user_pay_price;
                data_obj.remark = this.card_form.remark;
                data_obj.card_id = this.card_form.card_id;
                data_obj = JSON.stringify(data_obj);
                
                //将表单数据添加到数组中
                // data_uesr.push(this.ruleForm);
                await saveMemberCard({data_obj}).then(res => {
                    if (res.status == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        //延时执行
                        setTimeout(() => {
                            this.$emit('child-click', data_obj);
                        }, 1000);
                        
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    //
                    console.log(res);
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        //初始化加载 页面第一次加载时调用
	mounted() {
		this.getMemberCardBaseInfo();
		this.select_disabled_one = false;
        this.select_disabled_button = false;
    }
    }
</script>