//路由配置相关信息
import VueRouter from 'vue-router'
import Vue from 'vue'

import HelloWorld from '../components/HelloWorld.vue'
import HomeCashier from '../components/HomeCashier.vue'
import LoginPage from '../components/LoginPage.vue'
import ArchivesInfo from '../components/ArchivesInfo.vue'
import UserInfo from '../components/user_info/UserInfo.vue'
import UserCardList from '../components/user_info/UserCardList.vue'
import OrderDetail from '../components/user_info/OrderDetail.vue'


//1.安装插件    
Vue.use(VueRouter)

//2.创建路由对象
const routes = [
    {
        path: '/',
        name: 'HomeCashier',
        component: HomeCashier
    },
    {
        path: '/homeworld',
        component: HelloWorld
    },
    {
        path: '/homecashier',
        name: 'HomeCashier',
        component: HomeCashier
    },
    {
        path: '/loginpage',
        component: LoginPage
    },
    {
        path: '/ArchivesInfo/pet_id/:pet_id',
        name: 'ArchivesInfo',
        component: ArchivesInfo
    },
    {
        path: '/user_info/UserInfo/user_id/:user_id',
        name: 'UserInfo',
        component: UserInfo
    },
    {
        path: '/user_info/UserCardList/user_id/:user_id',
        name: 'UserCardList',
        component: UserCardList
    },
    {
        path: '/user_info/OrderDetail',
        name: 'OrderDetail',
        component: OrderDetail
    }
    // {
    //     path: '/archives',
    //     name: 'Archives',
    //     component: Archives
    // }
]

//3.创建router实例
const router = new VueRouter({
    routes,
})
//4.导出router实例
export default router