// user.js
import { httpGet, httpPost } from '../http.js'

//获取模糊查询的用户信息
export const getUserAllInfo = (params = {}) => httpGet({ url: 'cashier/User/getUserAllInfo', params })

// 获取会员卡列表
export const getUserCardList = (params = {}) => httpGet({ url: 'cashier/User/getUserCardList', params })
// 获取会员卡信息
export const getMemberCardInfo = (params = {}) => httpGet({ url: 'cashier/User/getMemberCardInfo', params })
// 退卡
export const returnCard = (data) => {
    return httpPost({
        url: 'cashier/User/returnCard',
        data
    })
}
// 获取用户订单列表
export const getUserOrderList = (params = {}) => httpGet({ url: 'cashier/User/getUserOrderList', params })
// 删除会员卡
export const delMemberCard = (params = {}) => httpGet({ url: 'cashier/User/delMemberCard', params })
//提交订单
export const addOrder = (data) => {
    return httpPost({
        url: 'cashier/Order/addOrder',
        data
    })
}

// 获取订单信息
export const getOrderDetail = (params = {}) => httpGet({ url: 'cashier/Order/getOrderDetail', params })

// 获取商品列表
export const getOrderGoodsList = (params = {}) => httpGet({ url: 'cashier/Order/getOrderGoodsList', params })
// 服务
export const getOrderConsumptionList = (params = {}) => httpGet({ url: 'cashier/Order/getOrderConsumptionList', params })
// 寄养列表
export const getEntrustList = (params = {}) => httpGet({ url: 'cashier/User/getEntrustList', params })
// 提醒
export const getWarnList = (params = {}) => httpGet({ url: 'cashier/User/getWarnList', params })