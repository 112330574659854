// http.js
import axios from 'axios'

// 环境的切换
// axios.defaults.baseURL = 'http://www.petshopone.com'
axios.defaults.baseURL = 'https://petshop.webe.asia'


// 请求拦截器
// axios.interceptors.request.use()
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 如果有token,就在请求头中添加token
    if (localStorage.cashier_pay_token) {
      console.log('-----');
      console.log(config);
        // config.headers.Authorization = localStorage.cashier_pay_token
        // config.headers.HTTP_TOKEN = localStorage.cashier_pay_token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

axios.defaults.timeout = 10000

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 响应拦截器
axios.interceptors.response.use(response => {
    if (response.status === 200) {
        if (response.data.status === 401) {
            //清空token
            // localStorage.cashier_pay_token = ''
        }
        if (response.data.code === 511) {
        // 未授权调取授权接口
        } else if (response.data.code === 510) {
        // 未登录跳转登录页
        } else {
        return Promise.resolve(response)
        }
    } else {
        return Promise.reject(response)
    }
}, error => {
  console.log('12321321312');
    console.log(error);
    // // 我们可以在这里对异常状态作统一处理
    // if (error.response.status) {
    //     // 处理请求失败的情况
    //     // 对不同返回码对相应处理
    //     return Promise.reject(error.response)
    // }
})

// get 请求
export function httpGet({
  url,
  params = {}
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      //增加token
      params:
        Object.assign(params, {
          token: localStorage.cashier_pay_token
        })
      // params
    }).then((res) => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

// post请求
export function httpPost({
  url,
  data = {},
  params = {}
}) {
    return new Promise((resolve, reject) => {
        axios({
        url,
        method: 'post',
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret += 'token=' + localStorage.cashier_pay_token
            return ret
        }],
        // 发送的数据
        data,
        // �url参数
        params,

        }).then(res => {
          resolve(res.data)
        })
    })
}