<template>
    <div id="app">
		<div style="padding: 20px 30px 118px;">
			<div class="pay-type">
				<h3>记账支付</h3>
				<div class="pay_list">
					<div class="pay_list_info"  v-on:click="pay_active('wx',0)">
						<el-avatar src="http://qiniuyk.webe.asia/pet/wx.png"></el-avatar>
						<p>微信</p>
					</div>
					<div class="pay_list_info "  v-on:click="pay_active('zfb',1)">
						<el-avatar src="http://qiniuyk.webe.asia/pet/zfb.png"></el-avatar>
						<p>支付宝</p>
					</div>
					<div class="pay_list_info"  v-on:click="pay_active('xj',2)">
						<el-avatar src="http://qiniuyk.webe.asia/pet/xj.png"></el-avatar>
						<p>现金</p>
					</div>
					<div class="pay_list_info"  v-on:click="pay_active('pos',3)">
						<el-avatar src="http://qiniuyk.webe.asia/pet/pos.png"></el-avatar>
						<p>POS机</p>
					</div>
					<div class="pay_list_info"  v-on:click="pay_active('tm',4)">
						<el-avatar src="http://qiniuyk.webe.asia/pet/tm.png"></el-avatar>
						<p>太米</p>
					</div>
				</div>
			</div>
			<div class="pay_info">
				<p>账单总价<span>¥ {{ goods_total }}</span></p>
				<p>折扣优惠<span>¥ {{ goods_discount }}</span></p>
				<p>会员卡抵扣<span>¥ {{ card_price }}</span></p>
				<p>应付金额<span>¥ {{ goods_total_discount }}</span></p>
				<p v-if="pty_type != ''">{{ pay_name }}<span>¥ {{ paid_price }}</span></p>
				<div v-if="card_id > 0" class="pay_info_input">
					<div v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)">
						<el-input class="pay_info_input_one"  placeholder="会员卡抵扣" v-model="card_price" @input="card_num"  @blur="blur_input_one" @focus="focus_input_one">
							<template slot="prepend">
								<span style="font-size: 18px; font-weight: 700; color: rgb(51, 51, 54);"> 会员卡抵扣</span>
							</template>
						</el-input>
					</div>
				</div>
				<div class="pay_info_input">
					<div v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)">
						<el-input class="pay_info_input_one" placeholder="实收金额" v-model="paid_price"  @blur="blur_input_one" @focus="focus_input_one">
							<template slot="prepend">
								<span style="font-size: 18px; font-weight: 700; color: rgb(51, 51, 54);"> 实收金额</span>
							</template>
						</el-input>
					</div>
				</div>
				<!-- <p v-if="pty_type == 'tm'">收款码<span style="font-size: 15px; color: #ccc;"> {{ collection_code }}</span></p> -->
				<!-- <p>
					抹零
					<span>¥ 10.00</span>
				</p> -->
				<p>
					备注
					<el-input
						type="textarea"
						placeholder="请输入内容"
						v-model="note"
						maxlength="150"
						show-word-limit
						:autosize="{ minRows: 5, maxRows: 5}"
						style="min-height: 33px;"
						>
					</el-input>
				</p>
			</div>
			<!-- 结账 -->
			<div class="pay_price">
				<p>
					<span>订单金额：</span>
					<span>¥ {{ paid_price }}</span>
				</p>
				<el-button v-if="pty_type != 'tm'" round class="price_button" v-on:click="order_pay_price()">结账</el-button>
				<el-button v-if="pty_type == 'tm'" round class="price_button" v-on:click="order_pay_price_sm()">扫码结账</el-button>
				
			</div>
		</div>
		<!-- 扫描付款吗 -->
		<el-dialog
			title="请出示付款码"
			:modal=false
			:show-close=false
			:visible.sync="dialogVisible"
			width="30%"
			:before-close="handleClose"
			>
			<div class="pay_info">
				<p>应付金额<span style="color: red;">¥ {{ paid_price }}</span></p>
			</div>
			<div>
				<p style="color:#45b787">金额确认无误后，请客户出示付款码</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog(2)">取 消</el-button>
				<!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
			</span>
		</el-dialog>
		<!-- 音频标签 -->
			<audio ref="audioPlayer" src="https://qiniuyk.webe.asia/pet/error.wav"></audio>
			<audio ref="audioPlayerSuccess" src="https://qiniuyk.webe.asia/pet/success.wav"></audio>
		<!-- 播放按钮 -->
    </div>
</template>

<script>
import { addOrder,checkOrder} from '@/api/home.js'
export default {
	name: 'DrawerPay',
	props: {
		msg: String
	},
	data() {
		return {
			jz_goods_num:0,  //结账的商品数量
			is_cika:1,  //是否选择次卡 1:正常 2:次卡
			pay_name:'',//支付方式
			paid_price:0,//实收金额
			goods_total: 0,//商品总价格
			goods_total_discount: 0,//商品总折扣价格
			goods_discount: 0,//商品折扣价格
			card_id: 0,//会员卡id
			card_price: 0,//会员卡抵扣金额
			payable_price: 0,//应付金额
			pty_type:'',//支付方式 'wx' 'zfb' 'xj' 'pos
			note: '',//备注
			is_focus:0,//是否获得焦点 1:获得焦点 0:未获得焦点
			collection_code: '',//
			scan_result: '',//
			dialogVisible: false,
			is_sm:0, //防止多次扫码
			
		};
    },

    methods: {
		handleClose(done) {
			if (this.scan_result == '') {
				this.$confirm('确认关闭？')
				.then(_ => {
					// 确认
					this.is_sm = 0;
					done();
				})
				.catch(_ => {});
			} else {
				// 关闭弹窗
				this.dialogVisible = false;
			}
			
		},
		// 关闭弹窗
		closeDialog(type=1) {
			this.dialogVisible = false;
			if (type ==2) {
				this.is_sm = 0;
			}
			
		},

		card_num(e,s){
			console.log(this.payable_price);
			// 扣除实收金额价格
			this.paid_price = this.goods_total_discount - e;
			this.card_price = e;
		},
		//选择支付方式
		pay_active(type,index){
			if (type == 'wx') {
				this.pay_name = '微信支付';
			} else if(type =='zfb') {
				this.pay_name = '支付宝支付';
			} else if (type == 'xj') {
				this.pay_name = '现金支付';
			} else if (type == 'pos') {
				this.pay_name = 'POS机支付';
			} else if(type == 'tm') {
				this.pay_name = '太米收款';
				this.is_sm = 0; //防止重复扫码，每次点击都要重新扫码
			} else {
				this.pay_name = '微信支付';
			}
			console.log(type);
			//修改 .pay_list_info 样式
			let pay_list_info = document.getElementsByClassName('pay_list_info');
			for (let i = 0; i < pay_list_info.length; i++) {
				pay_list_info[i].classList.remove('pay_active');
			}
			pay_list_info[index].classList.add('pay_active');
			this.pty_type = type;
		},
		//input获得焦点时
		focus_input_one(){
			this.is_focus = 1;
			this.changeActive();
		},
		//input失去焦点时
		blur_input_one(){
			this.is_focus = 0;
			this.removeActive();
		},
		//鼠标划过 修改样式
		changeActive(){
			//修改 .pay_info_input_one 样式
			let pay_info_input_one = document.getElementsByClassName('pay_info_input_one')[0];
			let el_input_group__prepend = pay_info_input_one.getElementsByClassName('el-input-group__prepend')[0];
			let el_input__inner = pay_info_input_one.getElementsByClassName('el-input__inner')[0];
			el_input_group__prepend.style.borderColor = '#45b787';
			el_input__inner.style.borderColor = '#45b787';			
		},
		//鼠标移出
		removeActive(){
			if (this.is_focus == 0) {
				//修改 .pay_info_input_one 样式
				let pay_info_input_one = document.getElementsByClassName('pay_info_input_one')[0];
				let el_input_group__prepend = pay_info_input_one.getElementsByClassName('el-input-group__prepend')[0];
				let el_input__inner = pay_info_input_one.getElementsByClassName('el-input__inner')[0];
				el_input_group__prepend.style.borderColor = '#d9d9d9';
				el_input__inner.style.borderColor = '#d9d9d9';	
			}
		},
		//扫码结账
		order_pay_price_sm(){
			// console.log('扫码结账1--2',this.collection_code);
			if (this.is_sm >= 1) {
				return false;
			}
			console.log('扫码开始');
			// 清空 collection_code
			this.collection_code = '';
			this.scan_result = '';
			this.dialogVisible = true;
			this.is_sm = 1;
			// console.log('扫码结账1--2',this.collection_code);

			
		},

		// 检查订单是否支付成功 checkOrder
		async checkOrder(order_id){
			await checkOrder({'order_id':order_id}).then(res => {
				console.log(res);
				if (res.code == 1) {
					localStorage.removeItem('click_goods_list');
					// localStorage.setItem('warn_goods_list',res.warn_goods_list);
					console.log('检查订单是否支付成功1231231');
					console.log(res);
					// if (res.warn_goods_list != 0) {
					// 	localStorage.warn_goods_list = JSON.stringify(res.warn_goods_list);	
					// }
					//提示成功
					this.$message({
						message: '结账成功',
						type: 'success'
					});
					this.$refs.audioPlayerSuccess.play();
					//延时操作
					setTimeout(() => {
						//刷新页面
						window.location.reload();
					}, 3000);
				} else {
					//提示成功 显示5秒
					this.$message({
						message: res.msg,
						type: 'warning'
					});
					// 播放音频 audioPlayer
					this.$refs.audioPlayer.play();
				}
			})
		},
			

		//结账
		async order_pay_price(){
			// 获取缓存 active_card_info 是否存在
			let active_card_info = localStorage.getItem('active_card_info');
			// 判断是否为空
			if (active_card_info == null) {
				if (this.pty_type == '') {
					this.$message({
						message: '请选择支付方式',
						type: 'warning'
					});
					return false;				
				}
			}
			
			//把订单信息放到对象里
			let order_info = {
				pty_type:this.pty_type,//支付方式
				paid_price:this.paid_price,//实收金额
				note:this.note,//备注
				//商品总价
				goods_total:this.goods_total,
				//商品总折扣价格
				goods_total_discount:this.goods_total_discount,
				//商品折扣价格
				goods_discount:this.goods_discount,
				//应付金额
				payable_price:this.payable_price,
				//会员卡id
				card_id:this.card_id,
				//会员卡抵扣金额
				card_price:this.card_price,
				// 是否是次卡
				is_cika:this.is_cika,
			};
			//获取缓存里的商品列表
			let goods_list = JSON.parse(localStorage.getItem('click_goods_list'));
			// console.log(goods_list)
			//获取缓存里的用户信息
			let user_info = JSON.parse(localStorage.getItem('user_info'));
			//把 order_info goods_list user_info 转成json 放到一个对象里
			let base_goods_info = {
				order_info:JSON.stringify(order_info),
				goods_list:JSON.stringify(goods_list),
				user_info:JSON.stringify(user_info),
				collection_code:this.scan_result,
			};
			console.log(base_goods_info);
			//提交订单
			await addOrder(base_goods_info).then(res => {
				console.log('123123');
				console.log(res);
				if (res.code == 1) {
					//清空缓存
					localStorage.removeItem('click_goods_list');
					// localStorage.setItem('warn_goods_list',res.warn_goods_list);
					console.log('1231231');
					console.log(res);
					if (res.warn_goods_list != 0) {
						localStorage.warn_goods_list = JSON.stringify(res.warn_goods_list);	
					}
					if (this.pty_type == 'tm') {
						this.checkOrder(res.order_id);
						// 重复执行 checkOrder 方法 5次 每次间隔2秒 *当前循环次数
						for (let i = 1; i <= 5; i++) {
							let $set_times = 8000 * i;
							console.log('事件：',$set_times);
							setTimeout(() => {
								this.checkOrder(res.order_id);
							}, $set_times);
						}
					} else {
						//提示成功
						this.$message({
							message: '结账成功',
							type: 'success'
						});
						// this.$refs.audioPlayerSuccess.play();
						// 延时操作
						setTimeout(() => {
							//刷新页面
							window.location.reload();
						}, 3000);
					}
					
					
				} else {
					if (this.pty_type == 'tm') {
						// 重复执行 checkOrder 方法 5次 每次间隔2秒 *当前循环次数
						for (let i = 0; i < 3; i++) {
							setTimeout(() => {
								this.checkOrder(res.order_id);
							}, 8000 * i);
						}
					}
					// //提示成功 显示5秒
					// this.$message({
					// 	message: res.msg,
					// 	type: 'warning'
					// });
					// // 播放音频 audioPlayer
					// this.$refs.audioPlayer.play();
					
					// //延时操作
					// setTimeout(() => {
					// 	//刷新页面
					// 	window.location.reload();
					// }, 2000);
					
				}
				
				// let i = 0;
				// let i_time = 2000;
				// let timer = setInterval(() => {
				// 	i++;
				// 	this.checkOrder(res.order_id);
				// 	if (i >= 3) {
				// 		clearInterval(timer * i);
				// 	}
				// }, i_time * i);
				

			})
			
		},

		



		//结账页面
		pay_view_price(){
			//通过缓存获取商品信息
			let goods_list = JSON.parse(localStorage.getItem('click_goods_list'));
			console.log(goods_list);
			let goods_total = 0;//商品总价
			//商品 折扣后总价格
			let goods_total_discount = 0;
			for (let i = 0; i < goods_list.length; i++) {
				//获取商品总价格
				goods_total += goods_list[i].price * goods_list[i].goods_num;
				//根据 min_count 获取商品折扣后的价格
				//获取商品折扣后的价格 转换float类型 保留两位小数 在做运算
				goods_total_discount += parseFloat(goods_list[i].min_count);
				// goods_total_discount += parseInt(goods_list[i].min_count);	
			}
			console.log(goods_total_discount)
			//获取商品共优惠多少钱 保留两位小数
			this.goods_discount = (goods_total - goods_total_discount).toFixed(2);
			//商品总价格
			this.goods_total = goods_total;
			//商品折扣后总价格
			this.goods_total_discount = goods_total_discount;
			//应付金额
			let payable_price = goods_total_discount;
			//实付金额
			this.paid_price = payable_price;	
			// 获取选中的会员信息 active_card_info
			let active_card_info = localStorage.getItem('active_card_info');
			// 判断是否为空
			if (active_card_info != null) {
				//转换成json
				active_card_info = JSON.parse(active_card_info);
				// 获取结账的商品数量
				this.jz_goods_num = goods_list.length;
				// 是否选择了次卡 
				if (active_card_info.type == 2) {
					if (this.jz_goods_num == 1) {
						// 次卡
						this.is_cika = 2;
						// 判断商品的ID 和 会员卡的service_id是否相同
						if (goods_list[0].goods_id == active_card_info.service_id) {
							// 使用次卡抵扣整个商品的价格
							this.card_price = payable_price
							this.card_id = active_card_info.id;
							this.paid_price = 0;
						} else {
							//没有会员卡
							this.card_price = 0;
						}
					} else {
						this.success = 0;
						this.success_msg = '次卡只能选择一个商品,无法选择多个商品';
					}
					
				} else {
					//计算可以抵扣的金额 最多不超过应付金额
					this.card_price = payable_price > active_card_info.balance ? active_card_info.balance : payable_price;
					this.card_id = active_card_info.id;
					this.paid_price = this.paid_price - this.card_price;
				}
				console.log('-----123-----')
				console.log(this.card_price);
			} else {
				//没有会员卡
				this.card_price = 0;
			}
			// console.log(active_card_info.balance);
		},
		handleScan(event) {
			if (event.key === 'Enter') {
				this.scan_result = this.collection_code;
				this.collection_code = '';
				// 检测是否是tm支付
				if (this.pty_type == 'tm' && this.dialogVisible == true) {
					console.log('扫码结束');
					this.closeDialog();
					this.order_pay_price();
				}
			} else {
				this.collection_code += event.key;
			}
			// console.log('扫码枪：', this.collection_code);
		},
		
    },
	//初始化加载 页面第一次加载时调用
	mounted() {
		this.pay_view_price();
		window.addEventListener('keydown', this.handleScan);
    },
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleScan);
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.price_button{
	float: right;
    width: 180px;
    margin: 0;
	border-radius: 20px;
    background-color: #45b787;
    border: 1px solid #45b787;
    color: #fff;
}
.pay_price p>span:last-child{
	height: 37px;
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
}
.pay_price p>span:first-child{
	height: 37px;
    display: inline-block;
    vertical-align: top;
    line-height: 37px;
}
.pay_price p{
	margin: 0;
	display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: #333336;
}
.pay_price{
	height: 38px;
    width: 488px;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 9;
    padding: 30px 30px 20px;
    box-shadow: 0 0 5px rgba(76,76,76,.35);
}


.pay_info p .el-textarea{
	margin-bottom: 10px;
	float: right;
    width: 363px;
    margin-right: 0;
	border-radius:8px;
}
.pay_info_input .el-input__inner:focus{
	border-color: #45b787;
}
/* .pay_info_input .el-input__inner:hover ~ div{
	border-color: #45b787;
}
.pay_info_input_one .el-input-group__prepend:hover + input{
	border-color: #45b787;
} */
.pay_info_input input{
	height: 50px;
    border-radius: 0 4px 4px 0;
    border-left: none;
    text-align: right;
    font-size: 22px;
    /* font-weight: 700; */
    font-family: PingFang;
    font-weight: 600;
}
.pay_info_input .el-input-group__prepend{
	color: #333336;
    background-color: #fff;
    /* border-radius: 20px 0 0 20px; */
}
.pay_info_input div:hover{
	/* border-color: #45b787; */

}
.pay_info_input div{
	height: 48px;
    border-radius: 4px 0 0 4px;
    border-right: none;
}
.pay_info_input{
	margin-bottom: 30px;
    margin-right: 0;
    width: 100%;
}
.pay_info p span{
	float: right;
    font-size: 22px;
    line-height: 30px;
    font-family: PingFang;
    font-weight: 600;
}
.pay_info p{
	margin-bottom: 30px;
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #333336;
    line-height: 25px;
}
.pay_info{
	margin-top:25px;
}

.el-avatar{
	background: #ffffff;
}

.pay-type>div>div.pay_active{
	box-shadow: 0 0 5px rgba(255,221,3,.5);
    border-color: #45b787;
    filter: grayscale(0);
    background: #ffffff;
}
.pay-type>div>div>p{
	height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333336;
    line-height: 20px;
    text-align: center;
}
.pay-type>div>div>span{
	margin: 13px 0 0 30.5px;
}
.pay-type>div>div{
	cursor: pointer;
    height: 100px;
    width: 21%;
    filter: grayscale(100%);
    background: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #ecebed;
    display: inline-block;
    margin: 9px;
}
.pay-type>div>div:nth-child(4n+1){
	margin-left: 0;
}
.pay_list{
	display: flex;
    flex-wrap: wrap;
}
.pay-type{
	/* padding: 20px 30px 118px; */
}
</style>
