<template>
    <div id="app">
        <div class="pet_block">
			<div class="pet_info">
				<div class="pet_title">
					<div>
						<span class="pet_title_name">宠物档案</span>
						<el-button round plain class="pet_button_one" @click="back_click_but()">返回上一页</el-button>
					</div>
					<div>
						<el-button round class="pet_button_one" @click="editor_pet_status=true">编辑宠物</el-button>
					</div>
				</div>
				<div class="pet_info_form">
					<el-row>
						<el-col :span="6">
							<div class="pet_info_img">
								<div>
									<el-avatar :src="pet_info.photo" style="height: 80px; width: 80px; line-height: 80px;"></el-avatar>
								</div>
								<div class="pet_info_img_text">
									<p style="font-weight: 600;">{{ pet_info.pet_name }}</p>
									<p>
										品种: <span style="margin-left: 5px;">{{ pet_info.variety }}</span>
									</p>
									<p>
										性别: <span style="margin-left: 5px;">{{ pet_info.sex }}</span>
									</p>
								</div>
							</div>
						</el-col>
						<el-col :span="2">
							<img src="../assets/pet_info_img.png" class="pet_info_two_img" alt="">
							<!-- <img src="http://qiniuyk.webe.asia/pet/pet_info_img.png" class="pet_info_two_img" alt=""> -->
						</el-col>
						<el-col :span="16">
							<el-row style="font-weight: 900;">
								<el-col :span="6">
									<el-form>
										<el-form-item style="width: 80px; margin-bottom: 0; margin-top: 20px;" label="生日:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.birthday }}</div>
											</div>
										</el-form-item>
									</el-form>
									<el-form>
										<el-form-item style="width: 80px;" label="毛色:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.color }}</div>
											</div>
										</el-form-item>
									</el-form>
								</el-col>
								<el-col :span="6">
									<el-form>
										<el-form-item style="width: 80px; margin-bottom: 0; margin-top: 20px;" label="绝育:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.is_sterilize }}</div>
											</div>
										</el-form-item>
									</el-form>
									<el-form>
										<el-form-item style="width: 80px;" label="标签:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.variety }}</div>
											</div>
										</el-form-item>
									</el-form>
								</el-col>
								<el-col :span="6">
									<el-form>
										<el-form-item style="width: 80px; margin-bottom: 0; margin-top: 20px;" label="体重:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.weight }} kg</div>
											</div>
										</el-form-item>
									</el-form>
									<el-form>
										<el-form-item style="width: 80px;" label="常用口粮:">
											<div style="margin-left: 10px;">
												<div>未知</div>
											</div>
										</el-form-item>
									</el-form>
								</el-col>
								<el-col :span="6">
									<el-form>
										<el-form-item style="width: 80px; margin-bottom: 0; margin-top: 20px;" label="肩高/体长:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.long }} cm</div>
											</div>
										</el-form-item>
									</el-form>
									<el-form>
										<el-form-item style="width: 80px;" label="备注:">
											<div style="margin-left: 10px;">
												<div>{{ pet_info.note }}</div>
											</div>
										</el-form-item>
									</el-form>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
				</div>
				<!-- 服务记录 -->
				<div class="pet_service_list">
					<div style="margin-top: 30px;background: #ffffff; border-radius: 12px;padding-bottom:20px;">
					<!-- <div style="display: inline-flex; border: 1px solid rgb(217, 217, 217); border-radius: 20px;"> -->
						<button type="button" class="el-button clb-btn el-button--default add_but" @click="addRecord"><span>添加记录</span></button>
						<el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true" style="padding: 10px; ">
							<el-tab-pane class="" label="服务记录" name="service_record" style="">
								<el-table
								:data="service_record_list"
								style="width: 100%">
									<el-table-column
										prop="goods_name"
										label="服务名称"
										width="180">
									</el-table-column>
									<el-table-column
										prop="category_name"
										label="消费类型"
										width="180">
									</el-table-column>
									<el-table-column
										prop="service_time"
										label="消费时间">
									</el-table-column>
									<el-table-column
										prop="next_time"
										label="下次服务时间">
									</el-table-column>
									<el-table-column
										prop="note"
										label="备注">
									</el-table-column>
									<el-table-column
										prop=""
										label="操作">
										<template slot-scope="scope">
											<el-button
											size="mini"
											@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
											<el-button
											size="mini"
											type="danger"
											@click="handleDelete(scope.$index, scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane name="deworming_record">
								<div style=" padding: 0;" slot="label">驱虫记录</div>
								<el-table
								:data="deworming_record_list"
								style="width: 100%">
									<el-table-column
										prop="service_name"
										label="使用药品"
										width="180">
									</el-table-column>
									<el-table-column
										prop="type_name"
										label="类型"
										width="180">
									</el-table-column>
									<el-table-column
										prop="service_time"
										label="服务时间">
									</el-table-column>
									<el-table-column
										prop="next_time"
										label="下次执行时间">
									</el-table-column>
									<el-table-column
										prop="note"
										label="备注">
									</el-table-column>
									<el-table-column
										label="操作">
										<template slot-scope="scope">
											<el-button
											size="mini"
											@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
											<el-button
											size="mini"
											type="danger"
											@click="handleDelete(scope.$index, scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane name="vaccine_record">
								<div style=" padding: 0;" slot="label">疫苗信息</div>
								<el-table
								:data="vaccine_record_list"
								style="width: 100%">
								<el-table-column
										prop="service_name"
										label="使用药品"
										width="180">
									</el-table-column>
									<el-table-column
										prop="type_name"
										label="类型"
										width="180">
									</el-table-column>
									<el-table-column
										prop="service_time"
										label="服务时间">
									</el-table-column>
									<el-table-column
										prop="warn_time"
										label="提醒时间">
									</el-table-column>
									<el-table-column
										prop="note"
										label="备注">
									</el-table-column>
									<el-table-column
										label="操作">
										<template slot-scope="scope">
											<el-button
											size="mini"
											@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
											<el-button
											size="mini"
											type="danger"
											@click="handleDelete(scope.$index, scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<!-- <el-tab-pane name="wash_care_report">
								<div style=" padding: 0;" slot="label">洗护报告</div>
								<el-table
								:data="wash_care_report_list"
								style="width: 100%">
									<el-table-column
										prop="date"
										label="日期"
										width="180">
									</el-table-column>
									<el-table-column
										prop="name"
										label="姓名"
										width="180">
									</el-table-column>
									<el-table-column
										prop="address"
										label="地址">
									</el-table-column>
								</el-table>
							</el-tab-pane> -->
						</el-tabs>
					</div>
				</div>
				<div class="pet_bottom">
					<div class="block">
						<!-- 分页 -->
						<el-pagination style="float: right;"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="currentPage"
						:page-sizes="[10, 20, 50, 70]"
						:page-size="page_size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 编辑宠物 -->
		<el-dialog
			title="编辑宠物"
			:visible.sync="editor_pet_status"
			>
			<SavePetInfo v-if="editor_pet_status" :pet_id="pet_id" @child-click="editPetInfo"></SavePetInfo>
		</el-dialog>
		<!-- 记录 -->
		<el-dialog
			title="记录"
			:visible.sync="record_pet_status"
			>
			<SavePetRecord v-if="record_pet_status" :active_type="activeType" :recird_id="recird_id" :pet_id="pet_id" @child-click="editRecordInfo"></SavePetRecord>
		</el-dialog>
		<el-button class="home2_but"  @click="home_fun" round>回到首页</el-button>
    </div>
</template>
<script>
import { getPetInfo , getPetRecordList, delPetServiceRecord} from '@/api/home.js'
import SavePetInfo from './pet_info/SavePetInfo.vue';
import SavePetRecord from './pet_info/SavePetRecord.vue';
export default {
		name: 'ArchivesInfo',
		props: {
			msg: String,
			next_pet_id: Number,
		},
		components: {
			SavePetInfo,
			SavePetRecord,
		},
		data() {
			return {
				// 宠物ID
				pet_id: 0,
				recird_id: 0,//编辑/删除时使用
				// 宠物信息
				pet_info: {
					birthday: '', // 生日
					variety: '', // 品种
					color:'', //颜色
					is_sterilize: '', // 是否绝育
					long: '', // 长
					note: '', // 备注
					pet_name:'',//名称
					photo:'',//头像
					sex:'',//性别
					weight:'',//体重
				},
				// 编辑宠物 弹窗
				editor_pet_status: false,
				// 记录 弹窗
				record_pet_status: false,

				currentPage: 1, // 分页
				total: 30, // 总数
				page_size: 10, // 每页显示多少条

				// 默认选项卡
				activeName:'service_record',//第一个选项卡的位置
				activeType: 1,//选项卡的类型
				// 服务记录
				service_record_list: [{
					goods_name: '', // 商品名称
					category_name: '', // 分类名称
					create_time: '', // 创建时间
					next_time:'',
					note:''
				}],
				// 驱虫记录
				deworming_record_list: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				// 疫苗记录
				vaccine_record_list: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				// 洗护报告
				wash_care_report_list: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
			}
		},
		methods: {
			//回到首页
			home_fun(){
				// 跳转到首页
				this.$router.push({ path: '/' });
			},
			// 返回上一页
			back_click_but(){
				if (this.next_pet_id == undefined) {
					this.$router.push('/');
				} else {
					// this.$router.push('/archives_info/' + this.next_pet_id);
					this.$emit('child-click', '2');
				}
				// this.$router.go(-1);
			},
			// 删除服务记录
			handleDelete(index, row){
				console.log(index);
				console.log(row);
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delPetServiceRecord({
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.status == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							if (this.activeType == 1) {
								// 服务记录
								this.service_record_list.splice(index, 1);
							} else if (this.activeType == 2) {
								// 驱虫记录
								this.deworming_record_list.splice(index, 1);
							} else if (this.activeType == 3) {
								// 疫苗记录
								this.vaccine_record_list.splice(index, 1);
							}
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 编辑记录
			handleEdit(index, row) {
				console.log(index);
				console.log(row);
				this.recird_id = row.id;
				this.record_pet_status = true;
			},
			// 添加记录
			addRecord(){
				this.recird_id = 0;
				this.record_pet_status = true;
			},
			// 记录
			handleClick(e) {
				console.log('点击了');
				console.log(e.name);
				if (e.name == 'service_record') {
					this.activeType = 1;
				} else if (e.name == 'deworming_record') {
					this.activeType = 2;
				} else if (e.name == 'vaccine_record') {
					this.activeType = 3;
				} else if (e.name == 'wash_care_report') {
					this.activeType = 4;
				}
				this.getPetRecordList();
			},
			// 获取档案
			async getPetRecordList(){
				console.log('获取档案');
				let res = await getPetRecordList({pet_id:this.pet_id,type:this.activeType,page:this.currentPage});
				console.log(res);
				if (res.status == 200) {
					if (this.activeType == 1) {
						// 服务记录
						this.service_record_list = res.data.list;
					}
					if (this.activeType == 2) {
						// 驱虫记录
						this.deworming_record_list = res.data.list;
					}
					if (this.activeType == 3) {
						// 疫苗记录
						this.vaccine_record_list = res.data.list;
					}

					// this.service_record_list = res.data.service_record_list; // 服务记录
					// this.deworming_record_list = res.data.deworming_record_list; // 驱虫记录
					// this.vaccine_record_list = res.data.vaccine_record_list; // 疫苗记录
					// this.wash_care_report_list = res.data.wash_care_report_list; // 洗护报告
					// 增加当前页数
					this.currentPage = 1;

					this.total = res.data.total;
				}
			},
			// 获取宠物信息
			async getPetInfo(){
				console.log('获取宠物信息');
				let res = await getPetInfo({pet_id:this.pet_id});
				if (res.status == 200) {
					this.pet_info.birthday = res.data.birthday; // 生日
					this.pet_info.variety = res.data.variety; // 品种
					this.pet_info.color = res.data.color; // 颜色					
					this.pet_info.long = res.data.long; // 长
					this.pet_info.note = res.data.note; // 备注
					this.pet_info.pet_name = res.data.pet_name; // 名称	
					this.pet_info.photo = res.data.photo; // 头像
					this.pet_info.weight = res.data.weight;//体重
					if (res.data.sex == 1) {
						this.pet_info.sex = '弟弟';
					} else {
						this.pet_info.sex = '妹妹';
					}
					if (res.data.is_sterilize == 1) {
						this.pet_info.is_sterilize = '未绝育';
					} else {
						this.pet_info.is_sterilize = '已绝育';
					}					
				} else {
					this.$message.error(res.msg);
				}
			},
			// 添加记录回调
			editRecordInfo(e){
				// 关闭窗口
				this.record_pet_status = false;
				// 重新获取记录
				this.getPetRecordList();
			},
			// 编辑宠物回调
			editPetInfo(e){
				// console.log('自组建调用');
				// 关闭窗口
				this.editor_pet_status = false;
				// 重新获取宠物信息
				this.getPetInfo();
				// console.log(e);
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},

			// //获取当前用户挂单数量
			// async login_cashier(){
				
			// 	let res = await loginCashier({username:this.username,password:this.password});
			// 	console.log('获取当前用户用户');
			// 	console.log(res);
			// 	if(res.status != 200){
			// 		//提示错误
			// 		this.$message({
			// 			message: res.msg,
			// 			type: 'error'
			// 		});
			// 	} else {
			// 		//保存员工信息 	到缓存
			// 		localStorage.setItem('cashier_admin', JSON.stringify(res.data));
			// 		//保存token		到缓存
			// 		localStorage.setItem('cashier_pay_token', res.data.token);
			// 		//跳转到首页
			// 		this.$router.push({
			// 			name: 'HomeCashier',
			// 			params: {}
			// 		});
			// 	}
			// }
		},
		mounted() {
			// 判断this.next_pet_id 是否是 undefined
			if (this.next_pet_id == undefined) {
				let url = window.location.href;   
				let pet_id = url.split('pet_id/')[1];   
				// console.log('1231231');
				this.pet_id = pet_id;
			} else {
				this.pet_id = this.next_pet_id;
			}
			// 获取宠物信息
			this.getPetInfo();
			console.log(this.pet_id);
			this.activeType = 1;
			//记录
			this.getPetRecordList();
		},
	}
</script>
<style>
.home2_but{
	background: #45b787;
	color: #ffffff;
	position: absolute;
    z-index: 1;
    left: 0%;
    top: 7%;
	/* 透明  */
	opacity: 0.6;
}
.pet_bottom{
	margin-top: 20px;
}
.add_but{
	margin-left:20px;
	width: 180px;
	border:1px solid #45b787;
	border-radius:20px;
	background-color: #45b787;
	color: #ffffff;
	right: 30%;
    position: absolute;
    margin-top: 10px;
	z-index: 100;
}
.pet_service_list .el-tabs--card>.el-tabs__header{
	border-bottom: 0;
}
.pet_service_list .el-tabs--card>.el-tabs__header .el-tabs__nav
{
	border:0;
}
.pet_service_list .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll{
	width: 40%;
	border: 1px solid #ccc;
	border-radius: 50px;
}
.pet_service_list{
	margin-top: 40px;
}
.pet_three_el_form_item{
	word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    margin-right: 20px;
}
.pet_info_two_img{
	height: 118px;
    width: 74px;
    margin-top: -1px;
}
.pet_info_img_text p{
	color: #333336;
    font-weight: 500;
    font-size: 16px;
    margin: 3px;
}
.pet_info_img_text{
	margin-left: 10px;
}
.pet_info_img{
	display: flex;
    margin: 18px;
}
.pet_info_form .el-row {
    height: 116px;
}
.pet_info_form{
	white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #ccc;
    margin-top: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ecebed;
}
.pet_title .pet_title_name{
	cursor: pointer;
    margin-right: 20px;
	font-size: 18px;
    color: #333336;
    font-weight: 700;
}
.pet_title .pet_button_one{
	border: 1px solid #d9d9d9;
    background-color: #fff!important;
    color: #595959!important;
}
.pet_title .pet_button_two{
	width: 106px;
}
.pet_title{
	display: flex;
    justify-content: space-between;
}
.pet_block{
	background-color: #fff;
    padding: 20px 20px 60px 20px;
    border-radius: 12px;
    overflow-y: hidden;
}
</style>