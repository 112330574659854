<template>
    <div id="app">
        <!-- <router-link to="/homeworld">首页</router-link> -->
        <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App'
  }
  var hiddenProperty = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
  var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
  var onVisibilityChange = function () {
       // 当可见性改变触发函数
       if (!document[hiddenProperty]) {
           console.log("现在是主屏显示");
           // 当页面在浏览器可视标签页显示时
       } else {
           console.log("离开标签页");
           // 当页面隐藏时
       }
  }
  document.addEventListener(visibilityChangeEvent, onVisibilityChange);
  </script>
  
  <style >
  .size{
    width: 100%;
    height: 100%;
  }
  html,body{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  #app {
    width: 100%;
    height: 100%;
  }
  </style>