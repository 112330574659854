// api.js
import { httpGet, httpPost } from '../http.js'


// 获取用户信息
export const getUserInfo = (params = {}) => httpGet({ url: 'cashier/User/getUserInfo', params })
//获取模糊查询的用户信息
export const getuserlist = (params = {}) => httpGet({ url: 'cashier/User/getSearchUserList', params })
//获取用户基础信息
export const getBaseUserInfo = (params = {}) => httpGet({ url: 'cashier/User/getBaseUserInfo', params })
//获取用户宠物信息
export const getUserPetList = (params = {}) => httpGet({ url: 'cashier/User/getUserPetList', params })
// 获取某个宠物的详情
export const getPetInfo = (params = {}) => httpGet({ url: 'cashier/Pet/getPetInfo', params })
//提交订单
export const addOrder = (data) => {
    return httpPost({
        url: 'cashier/Order/addOrder',
        data
    })
}
// 获取服务记录、驱虫、疫苗、洗护列表
export const getPetRecordList = (params = {}) => httpGet({ url: 'cashier/Pet/getPetRecordList', params })

//添加用户
export const addUser = (data) => {
    return httpPost({
        url: 'cashier/User/addUser',
        data
    })
}
//挂单
export const addPendingOrder = (data) => {
    return httpPost({
        url: 'cashier/Order/addPendingOrder',
        data
    })
}
//登录
export const loginCashier = (data) => {
    return httpPost({
        url: 'cashier/Login/login',
        data
    })
}

//获取当前用户挂单数量
export const getPendingOrderCount = (params = {}) => httpGet({ url: 'cashier/Order/getPendingOrderCount', params })
//获取挂单列表
export const getPendingOrderList = (params = {}) => httpGet({ url: 'cashier/Order/getPendingOrderList', params })
//解除挂单
export const removePendingOrder = (params = {}) => httpGet({ url: 'cashier/Order/removePendingOrder', params })
//删除挂单
export const delPendingOrder = (params = {}) => httpGet({ url: 'cashier/Order/delPendingOrder', params })
//通过手机号获取用户信息
export const getUserInfoByMobile = (params = {}) => httpGet({ url: 'cashier/User/getUserInfoByMobile', params })
export const getorglist = (params = {}) => httpGet({ url: 'apps/api/org/list', params })

export const save = (data) => {
  return httpPost({
    url: 'apps/wechat/api/save_member',
    data
  })
}

// 添加宠物
export const addpet = (data) => {
    return httpPost({
      url: 'cashier/Pet/addPet',
      data
    })
  }
// 复购提醒
export const saveGoodsWarn = (data) => {
  return httpPost({
    url: 'cashier/Pet/saveGoodsWarn',
    data
  })
}

// 获取办理会员卡基础信息
export const getMemberCardBaseInfo = (params = {}) => httpGet({ url: 'cashier/User/getMemberCardBaseInfo', params })
// 获取办理次卡基础信息
export const getMemberCountCardBaseInfo = (params = {}) => httpGet({ url: 'cashier/User/getMemberCountCardBaseInfo', params })

//保存用户会员卡
export const saveMemberCard = (data) => {
    return httpPost({
      url: 'cashier/User/saveMemberCard',
      data
    })
  }
// 获取当前用户会员卡列表
export const getUserCardList = (params = {}) => httpGet({ url: 'cashier/User/getUserCardList', params })


// 获取服务分类
export const getGoodsServiceCategoryList = (params = {}) => httpGet({ url: 'cashier/Goods/getGoodsServiceCategoryList', params })
// 获取服务商品列表
export const getGoodsServiceList = (params = {}) => httpGet({ url: 'cashier/Goods/getGoodsServiceList', params })

// 添加服务记录
export const addPetServiceRecord = (data) => {
    return httpPost({
      url: 'cashier/Pet/addPetServiceRecord',
      data
    })
}
// 获取服务详情
export const getPetServiceRecord = (params = {}) => httpGet({ url: 'cashier/Pet/getPetServiceRecord', params })
// 删除服务记录
export const delPetServiceRecord = (params = {}) => httpGet({ url: 'cashier/Pet/delPetServiceRecord', params })
// 删除宠物
export const delPet = (params = {}) => httpGet({ url: 'cashier/Pet/delPet', params })

// 查询订单是否支付成功
export const checkOrder = (params = {}) => httpGet({ url: 'cashier/Order/checkOrder', params })