<template>
    <div class="user_info_body">
        <el-tabs style="margin-left:1%" v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="基础信息" name="base_info">
                <BaseUserInfo v-if="activeType == 1" :user_id="user_id"></BaseUserInfo>
            </el-tab-pane>
            <el-tab-pane label="门店订单" name="order_list">
                <OrderList v-if="activeType == 2" :user_id="user_id"></OrderList>
            </el-tab-pane>
            <el-tab-pane label="消费商品" name="goods_list">
                <GoodsList v-if="activeType == 3" :user_id="user_id"></GoodsList>
            </el-tab-pane>
            <el-tab-pane label="服务消费" name="consume">
                <ConsumptionList v-if="activeType == 4" :user_id="user_id"></ConsumptionList>
            </el-tab-pane>
            <el-tab-pane label="寄养消费" name="foster_consume">
                <EntrustList v-if="activeType == 5" :user_id="user_id"></EntrustList>
            </el-tab-pane>
            <!-- <el-tab-pane label="活体消费" name="living_consume">活体消费</el-tab-pane> -->
            <!-- <el-tab-pane label="次卡记录" name="next_log">次卡记录</el-tab-pane> -->
            <el-tab-pane label="提醒" name="warn_list">
                <WarnList v-if="activeType == 8" :user_id="user_id"></WarnList>
            </el-tab-pane>
        </el-tabs>
        <el-button class="home_but"  @click="home_fun" round>回到首页</el-button>
    </div>
</template>
<script>
import { getUserInfo} from '@/api/user.js'
import BaseUserInfo from './BaseUserInfo.vue';
import OrderList from './OrderList.vue';
import GoodsList from './GoodsList.vue';
import ConsumptionList from './ConsumptionList.vue';
import EntrustList from './EntrustList.vue';
import WarnList from './WarnList.vue';




export default {
    name: 'UserInfo',
    components: {
        BaseUserInfo,
        OrderList,
        GoodsList,
        ConsumptionList,
        EntrustList,
        WarnList,
    },
    data() {
        return {
            activeName: '', //base_info:基础信息 order_list:门店订单 goods_list:消费商品 consume:服务消费 foster_consume:寄养消费 living_consume:活体消费 next_log:次卡记录 warn_list:提醒
            activeType: 0, //1:门店订单 2:消费商品 3:服务消费 4:寄养消费 5:活体消费 6:次卡记录 7:提醒
            user_id:0, //当前用户ID
        };
    },
    methods: {
        //回到首页
		home_fun(){
			// 跳转到首页
            this.$router.push({ path: '/' });
		},
        handleClick(tab, event) {
            if (tab.name == 'base_info') {
                // 门店订单
                this.activeType = 1;
                this.activeName = 'base_info';
            }
            if (tab.name == 'order_list') {
                // 门店订单
                this.activeType = 2;
                this.activeName = 'order_list';
            }
            if (tab.name == 'goods_list') {
                // 消费商品
                this.activeType = 3;
                this.activeName = 'goods_list';
            }
            if (tab.name == 'consume') {
                // 服务消费
                this.activeType = 4;
                this.activeName = 'consume';
            }
            if (tab.name == 'foster_consume') {
                // 寄养消费
                this.activeType = 5;
                this.activeName = 'foster_consume';
            }
            if (tab.name == 'living_consume') {
                // 活体消费
                this.activeType = 6;
                this.activeName = 'living_consume';
            }
            if (tab.name == 'next_log') {
                // 次卡记录
                this.activeType = 7;
                this.activeName = 'next_log';
            }
            if (tab.name == 'warn_list') {
                // 提醒
                this.activeType = 8;
                this.activeName = 'warn_list';
            }
            console.log(tab);
        }
    },
    mounted() {
        let url = window.location.href;   
		let user_id = url.split('user_id/')[1];   
        this.user_id = user_id;
        // 设置默认值
        this.activeName = 'base_info';
        this.activeType = 1;
        // console.log(this.user_id);
    }
};
</script>

<style>
.home_but{
	background: #45b787;
	color: #ffffff;
	position: absolute;
    z-index: 1000;
    left: 0%;
    top: 1%;
}
.el-tabs--card>.el-tabs__header .el-tabs__item{
    /* width: 12.5%; */
    width: 16.7%;
    text-align: center;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav {
    width: 95%;
}
.el-tabs--card>.el-tabs__header{
    border-bottom: 0;
    margin-left: 1%;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav {
    /* border-bottom:1; */
    /* border: 1px solid #ccc; */
}
.user_info_body {
    background-color: #fff;
    margin: 20px 20px 60px 20px;
    border-radius: 12px;
    overflow-y: hidden;
}
html, body {
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0;
    padding: 0;
}
</style>